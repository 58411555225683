const initialState = {
    data: null,
};
export const EditFileReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'EDIT_FILE_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case 'EDIT_FILE_RESET':
            return initialState;
        default:
            return state;
    }
}

export default EditFileReducer;