import Eeact from 'react';

export default function NavigationNames({MenuName}) {

    return (
        <>
            <h3 className='port-nav'>{MenuName}</h3>
        </>
    )
    
}