import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EDITUSER_SUCCESS = 'EDITUSER_SUCCESS';
export const EDITUSER_FAILURE = 'EDITUSER_FAILURE';

export const EDITUSERWALLET_SUCCESS = 'EDITUSERWALLET_SUCCESS';
export const EDITUSERWALLET_FAILURE = 'EDITUSERWALLET_FAILURE';




export const EditUserWalletAction = (walletAddress) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const user = {
            user_wallet: walletAddress,            
        }
        await axios.post("/api/Main/EditWalletUser", user, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('Successfully saved', {
                position: toast.POSITION.TOP_RIGHT
            });

            dispatch({
                type: EDITUSERWALLET_SUCCESS,
                payload: response.data,
            });
        });
    } catch (error) {
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: EDITUSERWALLET_FAILURE,
            payload: error,
        });
    }

}




export const EditUserAction = (user_email, user_name, user_phoneNumber,firstName, lastName, country) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const user = {
            user_email : user_email,
            user_name: user_name,
            user_phoneNumber: user_phoneNumber,
            user_firstName: firstName,
            user_lastName: lastName,
            user_country: country,
        }

        await axios.post("/api/Main/EditUser", user, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function(response){
            toast.success('Successfully saved', {
                position: toast.POSITION.TOP_RIGHT
            });

            dispatch({
                type: EDITUSER_SUCCESS,
                payload: response.data,
            });
        });
    } catch (error) {
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: EDITUSER_FAILURE,
            payload: error,
        });
    }

}

