const initialState = {
    data: null,
    last:''
};

export const newPdfRedux = (state = initialState, action) => {
    switch (action.type) {
        case 'NEWPDF_SUCCESS':
            return {
                ...state,
                last:'SUCCESS',
                data: action.payload,
            };
        case 'NEWPDF_FAILURE':
            return {
                ...state,
                last:'FAILURE',
                error: action.payload
            };
        case 'NEWPDF_RESET_REDUCER':
            return initialState;
        default:
            return initialState;
    }
}

export default newPdfRedux;