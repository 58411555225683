import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import DatePickerComponent from './datePicker';
import NavigationBar from './sideBarMenu';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { EditDocumentAction } from '../../actions/EditDocumentAction';
import { DeleteDocumentAction } from '../../actions/deleteDocument';
import { EditDocumentPropAction } from '../../actions/EditDocumentProp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Edit() {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state;
    const dispatch = useDispatch();
    const { testvalue } = useParams();
    const EditDocumentReducer = useSelector(state => state.EditDocumentReducer)
    const [name, setName] = useState('');
    const [isValidDocument, setIsValidDocument] = useState(false);
    const [description, setDescription] = useState('');
    const [del, setDel] = useState(false);
    const [title, setTitle] = useState('');
    useEffect(() => {
        dispatch(EditDocumentAction(id)); 
    }, []);

    const onClickSaveChanges = (event) => {
        event.preventDefault();
        dispatch(EditDocumentPropAction(id, name, description));
    }

    const onChangeName = (event) => {
        event.preventDefault();
        setName(event.target.value);
    }

    const onChangeDescription = (event) => {
        event.preventDefault();
        setDescription(event.target.value);
    }

    useEffect(() => {
        if (EditDocumentReducer && EditDocumentReducer.data) {
            setTitle(EditDocumentReducer.data.data.name);
            setName(EditDocumentReducer.data.data.name);
            setDescription(EditDocumentReducer.data.data.description);
            setIsValidDocument(true);
            setDel(EditDocumentReducer.data.data.del);
        }
    }, [EditDocumentReducer]);

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const deleteDocument = (event) => {
        event.preventDefault();
        dispatch(DeleteDocumentAction(id))

    } 

    const undeleteDocument = (event) => {

    } 

    const previewDocument = (event) => {
        navigate(`/index/viewer/${id}`, { state: { id: id } })

    } 
    return (
        <div style={{ display: 'flex' } }>

            <div className="row p-5 w-75" >    
                <ToastContainer />
                <div style={{minWidth: '300px'}}  className="col-md-8">
                    <section className="pt-3">
                    
                    <form id="editform" method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                        <h2>Edit Content</h2>   
                        <div className="form-horizontal">
                                {<h4 className="text-break">Document: {title}</h4>}
                            <hr></hr>
                                <div className="form-group mb-3 ">
                                        <label className="control-label col-md-2" for="Name">Name</label>
                                    <div className="col-md-12">
                                        <input className="form-control signle-line" onChange={onChangeName} required id="Name" name="Name" type="text" value={name}></input>
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label className="control-label col-md-2" for="Description">Description</label>
                                    <div className="col-md-12">
                                        <textarea className="form-control" style={{ minHeight: '100' }} onChange={onChangeDescription}  required id="Description" name="Description" value={description}></textarea>
                                    </div>
                                </div>                                        
                            <div className="form-group d-flex   justify-content-between">
                                <div>                        

                                        <button className="btn btn-success mt-2 m-1" type="submit" id="btnsave" onClick={onClickSaveChanges }>
                                        <span className="spinner-border spinner-border-sm" style={{display:'none'}} role="status" aria-hidden="true"></span>
                                            <i className="fa fa-square-check"></i> Save Changes
                                    </button>

                                    <a href="javascript:void(0)" className="btn btn-secondary mt-2 m-1" onClick={previewDocument}>
                                        <i className="fa fa-bullseye"></i> Preview
                                        </a>

                                        <Link className="text-muted btn mt-2 btn-warning text-white m-1" to={{
                                            pathname: `/index/statistics/${id}`,
                                        }} state={{ id: id }} title="Statistics">
                                            <i className="fa fa-chart-column"></i> Stats
                                        </Link>

                                        
                                </div>
                                    <div>
                                        {del ? (


                                            <div>
                                                <a href="javascript:void(0)" title="Undelete" className="btn btn-primary btn-circle mt-2" onClick={undeleteDocument}>
                                                    <i className="fa-solid fa-trash-can-arrow-up"></i>
                                                </a>
                                            </div>
                                        ) : (

                                            <a href="javascript:void(0)" title="Delete" className="btn btn-danger btn-circle mt-2" onClick={deleteDocument}>
                                                <i style={{ color: 'white' }} className="fa-regular fa-trash-can"></i>
                                            </a>
                                        )}
                                    
                                </div>
                            </div>
                        </div>
            
                        <div className="form-group mb-3 " style={{ margin: 'auto', width: '200px', height: '200px', position: 'relative', display: 'none' }} id="pgp">                    
                            </div>            
                    

                        <p className="d-none d-sm-block d-md-none"></p>
                        </form>
                    </section>

                    
                    <div className="mt-3">
                        <Link to="/index" className="btn btn-primary">
                            <i className="fa regular fa-delete-left"></i> Back to Home
                        </Link>
                    </div>

                </div>
            </div>

        </div>

        

    )
}

                    //@{await Html.RenderPartialAsync("_ShareTable", readers);}