const initialState = {
    data: null,
};
export const getDocumentWithIDReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GETDOCUMENTWITHID_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'GETDOCUMENTWITHID_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default getDocumentWithIDReducer;