import axios from 'axios';

export const INDEX_SUCCESS = 'INDEX_SUCCESS';
export const INDEX_FAILURE = 'INDEX_FAILURE';



export const IndexAction = () => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post('/api/Main/Index', null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: INDEX_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: INDEX_FAILURE,
            payload: errors,
        });

    };
}
