import axios from 'axios';

export const PRINTPDF_SUCCESS = 'PRINTPDF_SUCCESS';
export const PRINTPDF_FAILURE = 'PRINTPDF_FAILURE';

export const PrintPDFFiles = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const ID = {
            ID: id,
        }
        const response = await axios.post("api/Main/GetPDFFile", ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            'responseType': "blob",
        }).then(function (response) {
            var blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);

            const iframe = document.createElement('iframe');
            iframe.src = url;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            // Use onload to make pdf preview work on firefox
            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            };

        });
        dispatch({
            type: PRINTPDF_SUCCESS,
            payload: response.data,
        });
    } catch (error) {

        dispatch({
            type: PRINTPDF_FAILURE,
            payload: error,
        });
    }

}