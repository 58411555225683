
export const DeleteDocumentReducer = (state = null, action) => {
    switch (action.type) {
        case 'DELETEDOCUMENT_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'DELETEDOCUMENT_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default DeleteDocumentReducer;