import React, { useState } from 'react';
import styled from "styled-components";
import '@fortawesome/fontawesome-free/css/all.min.css';
// Input alanı
const Input = styled.input.attrs(props => ({
    type: "text",
    size: props.small ? 5 : undefined
}))`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  transition: border-color 0.3s;
  
  &:focus {
    border-color: #4caf50;
    outline: none;
  }
`;
// Animasyonlu label
const Label = styled.label`
  position: absolute;
  left: 30px; /* Sol pozisyonunu ayarla */
  top: 10px;  /* Üst pozisyonunu ayarla */
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;

  ${Input}:focus + &,
  ${Input}:not(:placeholder-shown) + & {
    top: -13px; /* Yükseklik animasyonu */
    left: 15px; /* Yukarıya çıkarken sol pozisyonu */
    font-size: 12px;
    color: #fffff; /* Aktifken rengi değiştir */
    background-color:white;
    padding:2px;

  }
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => {
    const [isFocused, setIsFocused] = useState(false);
    const shouldShowIcon = !isFocused && filterText === "";
    return (

        <div style={{ display: 'flex',position: 'relative' }} >
            {shouldShowIcon && (
                <i className="fa-solid fa-magnifying-glass" style={{
                    position: 'absolute',
                    top: '12px',
                    left: '10px',
                    transition: 'opacity 0.3s',
                }}></i>
            )}
            <Input
                id="searchPDFInput"
                type="text"
                className="form-control me-auto"
                placeholder=" "
                value={filterText}
                onChange={onFilter}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            <Label htmlFor="searchPDFInput">Search</Label>
        </div>
    )
};

export default FilterComponent;