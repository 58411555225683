import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Popover, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import moment from 'moment';

function ExportFields(data) {
    var sdata;
    ;
    // eslint-disable-next-line default-case
    switch (data.type) {
        case "sharedWithMe":
            sdata = data.data.map(row => ({
                Name: row.document.name,
                Description: row.document.description,
                Pages: row.document.totalPages,
                SharedBy: row.modifiedBy,
                SharedAt: row.modifiedAt.split('.')[0].replace('T', ' '),
                ExpireAt: row.expireDate.split('.')[0].replace('T', ' '),
                Status: row.expireDate.split('.')[0].replace('T', ' ').replace(/-/g, '/') < moment(new Date()).format("YYYY/MM/DD HH:mm:ss") ? "Active" : "Expire",
            }));
            break;
        case "myDocuments":
            sdata = data.data.map(row => ({
                Name: row.model.name,
                Description: row.model.description,
                Views: row.count,
                Viewers: row.countReader,
                Pages: row.model.totalPages,
                CreatedAt: row.model.createdAt.split('.')[0].replace('T', ' '),
                ModifiedAt: row.model.modifiedAt.split('.')[0].replace('T', ' '),
            }));
            break;
        case "recycleBin":
            sdata = data.data.map(row => ({
                Name: row.name,
                Description: row.description,
                CreatedAt: row.createdAt.split('.')[0].replace('T', ' '),
                ModifiedAt: row.modifiedAt.split('.')[0].replace('T', ' '),
            }));
            break;
        case "uploads":
            sdata = data.data.map(row => ({
                Name: row.document.name,
                Description: row.document.description,
                Reader: row.readedBy,
                SharedAt: row.modifiedAt.split('.')[0].replace('T', ' '),
                ExpireAt: row.expireDate.split('.')[0].replace('T', ' '),
            }));
            break;
        case "history":
            sdata = data.data.map(row => ({
                Name: row.userName,
                Amount: row.amount,
                AmountValue: row.amountValue,
                AmountCurrency: row.amountCurrency,
                CreatedAt: row.createdAt.split('.')[0].replace('T', ' '),
            }));
            break;
    }
    return sdata;
}

const HamburgerMenu = (data) => {
    ;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const handleClick = (event, title) => {
        
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setSelectedMenu(title);
        if (data && data.data && data.data.length > 0) {
            if (title === "Export CSV") {
                downloadCSV();
            } else if (title === "Export Excel"){
                exportToExcel();
            }
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const downloadCSV = (filename = 'exported_table.csv') => {
        var exportData = ExportFields(data);
        /*const exportData = data.data.map(row => ({
            Name: row.model.name,
            Description: row.model.description,
            Views: row.count,
            Viewers: row.countReader,
            Pages: row.model.totalPages,
            CreatedAt: row.model.createdAt.split('.')[0].replace('T', ' '),
            ModifiedAt: row.model.modifiedAt.split('.')[0].replace('T', ' '),
        }));*/

        // CSV formatına dönüştürme
        const csvRows = [];

        // Başlık satırları ekleniyor
        const headers = Object.keys(exportData[0]);
        csvRows.push(headers.join(',')); // Virgülle ayır

        // Verileri CSV formatına çevirme
        exportData.forEach(row => {
            const values = headers.map(header => `"${row[header]}"`); // Değerleri al ve tırnak içine al
            csvRows.push(values.join(',')); // Virgülle ayır
        });

        // CSV içeriğini oluştur
        const csvContent = csvRows.join('\n');

        // Blob kullanarak dosya indirme işlemi
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        a.click();
    };

    const exportToExcel = () => {
        var exportData = ExportFields(data);
        /*const exportData = data.data.map(row => ({
            Name: row.model.name,
            Description: row.model.description,
            Views: row.count,
            Viewers: row.countReader,
            Pages: row.model.totalPages,
            CreatedAt: row.model.createdAt.split('.')[0].replace('T', ' '),
            ModifiedAt: row.model.modifiedAt.split('.')[0].replace('T', ' '),
        }));*/

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        // Excel dosyasını indir
        XLSX.writeFile(workbook, "table-data.xlsx");
    };

   

    const menuItems = [
        { title: 'Export CSV', subItems: [] },
        { title: 'Export Excel', subItems: [] },
    ];

    return (
        <div>
            <Toolbar style={{ maxHeight: '45px',minHeight: '45px' }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={(event) => handleClick(event, null)}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List>
                    {menuItems.map(({ title, subItems }) => (
                        <div key={title}>
                            <ListItem button onClick={(event) => handleClick(event, title)}>
                                <ListItemText primary={title} />
                            </ListItem>
                            {selectedMenu === title && subItems.length > 0 && (
                                <List component="div" disablePadding>
                                    {subItems.map((subItem) => (
                                        <ListItem button key={subItem}>
                                            <ListItemText primary={subItem} style={{ paddingLeft: '32px' }} />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </div>
                    ))}
                </List>
            </Popover>
        </div>
    );
};

export default HamburgerMenu;