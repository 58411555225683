
const initialState = {
    flag: false,

};

export const ModalFlagReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MODALFLAG':
            return {
                ...state,
                flag: !action.payload,
            };
        default:
            return state;
    }
}

export default ModalFlagReducer;