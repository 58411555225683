import axios from 'axios';

export const ISAUTHORIZE_SUCCESS = 'ISAUTHORIZE_SUCCESS';
export const ISAUTHORIZE_FAILURE = 'ISAUTHORIZE_FAILURE';

export const IsAuthorizeDocumentAction = (id) => async (dispatch) => {

    
    try {
        const documentID = {
            ID: id,
        }
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const response = await axios.post("/api/Main/IsAuthorize", documentID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: ISAUTHORIZE_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: ISAUTHORIZE_FAILURE,
            payload: errors.response.data.error,
        });

    };
}
