import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const SENDREPORTEMAIL_SUCCESS = 'SENDREPORTEMAIL_SUCCESS';
export const SENDREPORTEMAIL_FAILURE = 'SENDREPORTEMAIL_FAILURE';
export const SendReportEmail = (userId, id) => async (dispatch) => {
    try {
        const input = {
            userId: userId,
            id: String(id),
        }
    
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        await axios.post('/api/Main/SendReport', input, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('Document reported successfully', {
                position: toast.POSITION.TOP_RIGHT
            });
            

            //authenticated redirect home page
            dispatch({
                type: SENDREPORTEMAIL_SUCCESS,
                payload: response.data,
            });
        });
    } catch (errors) {
        toast.error(errors.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        //not authenticated give error
        dispatch({
            type: SENDREPORTEMAIL_FAILURE,
            payload: errors,
        });

    };
}

