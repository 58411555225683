import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const NEWPDF_SUCCESS = 'NEWPDF_SUCCESS';
export const NEWPDF_FAILURE = 'NEWPDF_FAILURE';
export const NEWPDF_RESET_REDUCER = 'NEWPDF_RESET_REDUCER';
export const newPdfAction = (formFile, Name, Description) => async (dispatch) => { 
    if (!formFile) {
        dispatch({
            type: NEWPDF_RESET_REDUCER,
            payload: null,
        });
    } else {
        const formData = new FormData();
        formData.append('formFile', formFile);
        formData.append('Name', Name);
        formData.append('Description', Description);
        try {
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'));
            const token = tokenStorage.data.accessToken;
            const response = await axios.post('/api/Main/NewPDF', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('Document uploaded successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: NEWPDF_SUCCESS,
                    payload: response.data,
                });
            });

        } catch (error) {
            for (var a = 0; a < error.response.data.error.errors.length; a++) {
                toast.error(error.response.data.error.errors[a], {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({
                type: NEWPDF_FAILURE,
                payload: error.response.data,
            });
        };
    }
};

