import axios from 'axios';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LOADING = 'LOGIN_LOADING';

export const loginAction = (email, password, recaptchaToken) => async (dispatch) => {

    dispatch({ type: LOGIN_LOADING });

    await axios.post('/api/Auth/CreateToken', { email, password, recaptchaToken }, JSON).then(function (response) {
        //localStorage.setItem('AccessToken', JSON.stringify(response.data));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data,
            });
        })
        .catch(function (errors) {

            if (errors.response.data.errors) {
                //not authenticated give error
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: errors.response.data.errors,
                });
            } else {

                //not authenticated give error
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: errors.response.data.error.errors,
                });
            }

        });     
}