import axios from 'axios';

export const GETSHAREDWITHME_SUCCESS = 'GETSHAREDWITHME_SUCCESS';
export const GETSHAREDWITHME_FAILURE = 'GETSHAREDWITHME_FAILURE';



export const getSharedWithMe = () => async (dispatch) => { 
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post('/api/App/getshared', null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETSHAREDWITHME_SUCCESS,
            payload: response.data,
        });

    }catch(errors){
        dispatch({
            type: GETSHAREDWITHME_FAILURE,
            payload: errors,
        });

    };
}
