const initialState = {
    data: null,
}
export const SelectedCheckboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SELECTEDCHECKBOXVAL':
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
}

export default SelectedCheckboxReducer;