const initialState = {
    data: null,
};
export const GetSharedFromMeAllReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GETSHAREDFROMMEALL_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'GETSHAREDFROMMEALL_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default GetSharedFromMeAllReducer;