import axios from 'axios';

export const LINKENTRY_SUCCESS = 'LINKENTRY_SUCCESS';
export const LINKENTRY_FAILURE = 'LINKENTRY_FAILURE';

export const LinkEntryAction = (id) => async (dispatch) => {


    try {
        const documentID = {
            ID: id,
        }
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const response = await axios.post('/api/Main/LinkEntry', documentID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: LINKENTRY_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: LINKENTRY_FAILURE,
            payload: errors.response,
        });

    };
};


export const PTG_SUCCESS = 'PTG_SUCCESS';
export const PTG_FAILURE = 'PTG_FAILURE';

export const PasswordlessTokenGenerationAction = (magicKey, magicEmail) => async (dispatch) => {


    try {
        const magicReq = {
            'magicKey': magicKey,
            'magicEmail': magicEmail
        }
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))        
        const response = await axios.post('/api/Main/PasswordlessTokenGeneration', magicReq, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',               
            }
        });
        dispatch({
            type: PTG_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: PTG_FAILURE,
            payload: errors.response.data,
        });

    };
}



export const PLL_SUCCESS = 'PLL_SUCCESS';
export const PLL_FAILURE = 'PLL_FAILURE';

export const PasswordlessLoginAction = (entry, userId, token) => async (dispatch) => {


    try {
        const pllReq = {
            'entry': entry,
            'userId': userId,
            'token': token
        }
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const response = await axios.post('/api/Main/PasswordlessLogin', pllReq, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        dispatch({
            type: PLL_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: PLL_FAILURE,
            payload: errors.response.data,
        });

    };
}



