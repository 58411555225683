const initialState = {
    data: null,
    show: null,
}
export const ShowModalFlagReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HIDEMODALFLAG':
            return {
                ...state,
                data: action.payload
            };
        case 'SHOWMODALFLAG':
            return {
                ...state,
                data: true,
                show: action.payload,
            };
        case 'RESETMODALFLAG':
            return initialState;
        default:
            return state;
    }
}

export default ShowModalFlagReducer;