import React from 'react';
import '../styles/style.css';
import booby from '../../images/BymmyLoadingScreen.svg';
const SplashScreen = () => {
    return (
        <div className="splash-screen">
            <img src={booby} alt="brand" ></img>
        </div>
    );
};

export default SplashScreen;