import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/login.css';
import { sendVerificationEmail } from '../../actions/sendVerificationEmail';
import { useDispatch, useSelector } from 'react-redux'
import booby from '../../images/Logo_removebg.png';
export function RegisterNotConfirmedPosted (){
    const dispatch = useDispatch();
   
    useEffect(() => {
        dispatch(sendVerificationEmail());
    },[])

        return (
            
            <div className="row AuthTL">
                <section className="col-md-4">
                    <form>
                        <p style={{ textAlign: 'center'} }>Please check your email for confirmation link.</p>
                    </form>
                </section>
                </div>
        );
    
}
