const initialState = {
    data: null,
    success:false
}
export const registerConfirmationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REGISTERCONFIRMATION_SUCCESS':
            return {
                ...state,
                data: action.payload,
                success: true
            };
        case 'REGISTERCONFIRMATION_FAILURE':
            return {
                ...state,
                error: action.payload,
                success: false
            };
        default:
            return state;
    }
}

export default registerConfirmationReducer;