import React, { useState, useEffect } from 'react';
import Modal from "./modal";
import DatePickerComponent from './datePicker'
import { fetchEditMultiSharePost } from '../../actions/fetchEditMultiSharePost';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
const MassShareDocument = (props) => {
    var date = new Date();
    const dateString = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() % 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;
    const fetchEditShareMultiPostReducer = useSelector(state => state.FetchEditShareMultiPostReducer);
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;
    const [email_address, setEmail_address] = useState('');
    const [multiExpireDate, setMultiExpireDate] = useState(dateString);
    const [allow_download_multi, setAllow_download_multi] = useState(false);
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const [titlePros, setTitlePros] = useState("MultiShare");
    const sharePostToAll = (event) => {
        event.preventDefault();
        dispatch(fetchEditMultiSharePost(id, email_address, multiExpireDate, allow_download_multi));
        
        
    }
    const changeEmailAddress = (event) => {
        event.preventDefault();
        setEmail_address(event.target.value);
    }

    const changeAllowDownload = () => {
        setAllow_download_multi(!allow_download_multi);
    }

    const changeMultiExpireDate = (date) => {
        //event.preventDefault();
        setMultiExpireDate(date);
    }

    const style = {
        zIndex: '999',
        marginTop: '10px',
        position: 'absolute',
        backgroundColor: 'white',
        width: '500px',
        height: '500px',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign: 'left',
    }

    useEffect(() => {
        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true && ShowModalFlagReducer.show === "MultiShare") {
                setShowModal(true);
            } else if (ShowModalFlagReducer.data === false){
                setShowModal(false);
            }
        }    
    }, [ShowModalFlagReducer])
    
    return (
        <div>
            <Modal onHide={() => setShowModal(false)} styleProps={style} onClose={() => setShowModal(false)} show={showModal} titleProps={titlePros}>
                <div>
                </div>
                <div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <input type="hidden" name="id" value="1"></input>
                            <div className="form-group">
                                <label htmlFor="message-text" className="col-form-label">
                                    Recipients:
                                </label>
                                <textarea className="form-control" onChange={changeEmailAddress} style={{ minHeight: '160px' }} id="multi-receipt" required name="email-address" placeholder="Email address per line">
                                </textarea>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={changeAllowDownload} type="checkbox" name="allow-download-multi"></input>
                                <label className="form-check-label" htmlFor="allow-download-multi">Allow Downloading<span className="text-muted">{' '}<small>Viewers can download your file</small></span></label>
                            </div>
                            <div className="form-group mt-3 mb-3">
                                <label htmlFor="multi-expire-date" className="form-label">Expire Date:</label>
                                <DatePickerComponent changeMultiExpireDate={changeMultiExpireDate} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary m-1 lift" onClick={() => setShowModal(false)}>Close</button>
                    <button onClick={sharePostToAll} disabled={fetchEditShareMultiPostReducer.busy} style={{ color: 'white' }} className="btn btn-info m-1 sharenow">
                        <span className="spinner-border spinner-border-sm" style={{ display: fetchEditShareMultiPostReducer.busy ? 'inline-block' : 'none' }} role="status" aria-hidden="true"></span>
                        Share to All</button>
                    <div id="loader5" className="alert" style={{ display: fetchEditShareMultiPostReducer.busy ? 'block' : 'none' }}>
                        Sharing document, please wait...
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default MassShareDocument;