import axios from 'axios';

export const GETUSERLEDGER_SUCCESS = 'GETUSERLEDGER_SUCCESS';
export const GETUSERLEDGER_FAILURE = 'GETUSERLEDGER_FAILURE';

export const GetUserLedger = () => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post("/api/Main/GetUserLedger", null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETUSERLEDGER_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: GETUSERLEDGER_FAILURE,
            payload: errors,
        });

    };
}