export const STATISTICID = 'STATISTICID';

export const StatisticId = (id, name, show) => async (dispatch) => {
    if (show === true) {
        dispatch({
            type: STATISTICID,
            payload: id,
            name: name,
            showFlag: show,
        });
    } else {
        dispatch({
            type: 'CLEAR_STATE_STATISTIC_ID',
            payload: id,
            name: name,
            showFlag: show,
        });
    }
}
