import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const STOREUSERIMAGE_SUCCESS = 'STOREUSERIMAGE_SUCCESS';
export const STOREUSERIMAGE_FAILURE = 'STOREUSERIMAGE_FAILURE';
export const STOREUSERIMAGE_RESET = 'STOREUSERIMAGE_RESET';

export const storeUserImage = (formFile) => async (dispatch) => {
    if (!formFile) {
        dispatch({
            type: STOREUSERIMAGE_RESET,
            payload: null,
        });
    } else {
        const formData = new FormData();
        formData.append('formFile', formFile);
        formData.append('Name', formFile ? formFile.name : "");
        try {
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'));
            const token = tokenStorage.data.accessToken;
            await axios.post("/api/Main/StoreUserImage", formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('User Image changed successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: STOREUSERIMAGE_SUCCESS,
                    payload: response.data,
                });
            })
 

        } catch (errors) {
            toast.error('User Image upload failed!', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: STOREUSERIMAGE_FAILURE,
                payload: errors.response,
            });
        };
    }
}

