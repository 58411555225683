import React, { useState, useEffect, useCallback } from 'react';
import Modal from "./modal";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { storeUserImage } from '../../actions/storeUserImage';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { getUserInformation } from '../../actions/getUserInformation';
import { DeleteUserImage } from '../../actions/DeleteUserImage';
import { ShowModalFlag } from '../../actions/showModalFlag';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contain = styled.div`
    display: block;
    border-radius: 50 %;
    overflow: hidden;
    min-width: 80px;
    height: 256px;
    border-radius: 50 %;
    text-align: center;
    align-items: center;
    margin-right: 20px;
    border:0.5px solid black;
`;
const UserImage = (props) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const [titlePros, setTitlePros] = useState("Change Photo");
    const hiddenFileInput = React.useRef(null);
    const [userFirstCharacter, setUserFirstCharacter] = useState("");
    const [userDoc, setUserDoc] = useState(JSON.parse(localStorage.getItem('user')));
    const [previewImage, setPreviewImage] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const storeUserImageReducer = useSelector(state => state.storeUserImageReducer);
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer);
    const DeleteUserImageReducer = useSelector(state => state.DeleteUserImageReducer);
    /*const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'image/*': [] 
        }
    });*/
    useEffect(() => {
        dispatch(getUserInformation());
        //dispatch(DeleteUserImage(false));
        //dispatch(storeUserImage());
    },[])

    useEffect(() => {
        if (getUserInformationReducer && getUserInformationReducer.data && getUserInformationReducer.data.isSuccessful) {
            setPreviewImage(JSON.parse(JSON.stringify(getUserInformationReducer.data.data.image)));
        }
    }, [getUserInformationReducer])


    useEffect(() => {
        if (DeleteUserImageReducer && DeleteUserImageReducer.data && DeleteUserImageReducer.data.isSuccessful) {
            setPreviewImage(JSON.parse(JSON.stringify(DeleteUserImageReducer.data.data.image)));
        }
    }, [DeleteUserImageReducer])

    useEffect(() => {
        if (storeUserImageReducer && storeUserImageReducer.data) {
            dispatch(getUserInformation());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeUserImageReducer])


    useEffect(() => {
        if (selectedImage) {

            const objectUrl = URL.createObjectURL(selectedImage);
            setPreviewImage(objectUrl);
        } else {
            setPreviewImage();
        }
    }, [selectedImage]);

    const handleUserImageChange = (event) => {
        if (event.target.files.length > 0) {
            if (event.target.files[0].size < 300000) {
                setSelectedImage(event.target.files[0]);
                dispatch(storeUserImage(event.target.files[0]));
            }
            else if (300000 < event.target.files[0].size && event.target.files[0].size < 500000) {
                setSelectedImage(event.target.files[0]);
                dispatch(storeUserImage(event.target.files[0]));
                toast.info('User Image size upper than 300KB!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error('User Image size upper than 500KB!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        } 
    }

    const removeUserImageChange = (event) => {
        setSelectedImage();
        dispatch(DeleteUserImage(true));
    }

    const style = {
        zIndex: '999',
        marginTop: '10px',
        backgroundColor: 'white',
        width: '550px',
        height: '530px',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign: 'left',
    }

    useEffect(() => {

        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true) {
                setShowModal(true);
            } else if (ShowModalFlagReducer.data === false) {
                setShowModal(false);
            }
        }
    }, [ShowModalFlagReducer])

    useEffect(() => {

        if (userDoc && userDoc !== null) {

            var names = userDoc.userName.split(' ');
            var sum = "";
            for (var a = 0; a < names.length; a++) {
                sum = sum + names[a][0].toUpperCase();
            }
            setUserFirstCharacter(sum);
        }
    }, [userDoc]);

    const changeShowModalFlag = (e) => {
        setShowModal(false);
        dispatch(ShowModalFlag(false));
    }
    //onClick: event => event.stopPropagation() ,
    ///<Container  {...getRootProps({ className: 'dropzone mt-3', isFocused, isDragAccept, isDragReject })}>
    //                                <input {...getInputProps()} onChange={handleUserImageChange} accept="image/*" />
    //</Container >
    return (
        <div>
            <Modal onHide={changeShowModalFlag} styleProps={style} onClose={changeShowModalFlag} show={showModal} titleProps={titlePros}>
                <div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {previewImage  ? 
                                    <>
                                    <div className="dropzone mt-3">
                                        <img style={{ width: '50%', borderRadius: '50%' }} src={'data:image/jpeg;base64,' + previewImage} width="256" height="256"/>
                                    </div>
                                    </>

                                    :
                                    <>
                                        <div className="dropzone mt-3 accountPictureLetter">
                                            <Contain width="256" height="256" style={{ width: '50%', borderRadius: '50%' }}>
                                            <div className="userCharacterStyle">
                                                {userFirstCharacter}   
                                            </div>
                                            </Contain>
                                        </div>
                                    </>
                                 }
                                
                            
                            <div className="d-block" style={{ padding: '15px', marginTop: '15px'} }>
                                <label htmlFor="load-user-photo" className="p-1">
                                    <div style={{ color: '#59b87d' }}>
                                        <i className="fa-solid fa-cloud-arrow-up"></i>
                                        &nbsp;Upload Your Photo
                                    </div>
                                </label>
                                <input type="file" onChange={handleUserImageChange} accept="image/*" name="changePhoto" style={{ display: 'none' }} id="load-user-photo" />

                                <br />
                                <label className="p-1" onClick={removeUserImageChange}>
                                    <div>
                                        <i className="fa-solid fa-trash"></i>
                                        &nbsp;Remove Your Photo
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>               
            </Modal>
        </div>
    );
}
export default UserImage;