import axios from 'axios';

export const VIEWER_SUCCESS = 'VIEWER_SUCCESS';
export const VIEWER_FAILURE = 'VIEWER_FAILURE';

export const ViewerAction = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const docID = {
           ID: id,
        }
        const response = await axios.post('/api/Main/Viewer', docID , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: VIEWER_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: VIEWER_FAILURE,
            payload: errors,
        });

    };
}

