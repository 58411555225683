import React, { Component, useEffect } from 'react';
import '../styles/login.css';
import booby from '../../images/Logo_removebg.png';
import { Link, useNavigate } from "react-router-dom";
export function ResetPasswordEmail() {
    const navigate = useNavigate();
    /*useEffect(() => {
        let redirectTimeout: 0;
            redirectTimeout = setTimeout(() => {
                navigate("/");
            }, 5000);
        return () => {
            clearTimeout(redirectTimeout);
        }
    }, []);*/

    return (
        <div className="row AuthTL">
            <section className="col-md-4">
                <form>
                    <p style={{ textAlign: 'center', fontSize: '18px' }}>Please check your email to reset your password.</p>
                    <p style={{ 'textAlign': 'center' }}>
                        <Link to="/">Home</Link>

                    </p>
                </form>
            </section>
        </div>
    );

}
