const initialState = {
    data: null,
}
export const UnDeleteMoreThanOneReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UNDELETEMORETHANONEDOCUMENT_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'UNDELETEMORETHANONEDOCUMENT_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default UnDeleteMoreThanOneReducer;