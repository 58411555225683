import React, { useEffect } from 'react';
import { Navigate, Route, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
const ProtectedRoute = ({component: Component, ...rest}) => {
    const loginRedux = useSelector(state => state.loginRedux)
    const navigate = useNavigate();


    //useEffect(() => {
        if (localStorage.getItem('user') !== null) {
            var expireDate = new Date(JSON.parse(localStorage.getItem('AccessToken')).data.accessTokenExpiration).getTime();            
            console.log("datediff", new Date().getTime() - expireDate, new Date(JSON.parse(localStorage.getItem('AccessToken')).data.accessTokenExpiration), new Date() )
            if (new Date().getTime() > expireDate) {
                console.log("session expired.");
                sessionStorage.clear();
                localStorage.clear();
            }
        }
    //}, []);
    
    return (
        localStorage.AccessToken ? 
        <Outlet/>
             : 
        <Navigate to="/login" replace />

        )
    
        
}
export default ProtectedRoute;