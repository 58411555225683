import React, { useState, useEffect } from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import '../styles/sideBar.css';
import booby from '../../images/Logo_removebg.png';
import { Link, NavLink } from 'react-router-dom'; 
import { SplashScreen } from '../../actions/splashScreen';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch } from 'react-redux';

export default function AccountMenu({ activeChildMenu }) {
    const [activeMenu, setActiveMenu] = useState(activeChildMenu);
    const SplashScreenReducer = useSelector(state => state.SplashScreenReducer);
    const [sidebarVisible, setSidebarVisible] = useState(true); // Sidebar g�r�n�r m�?
    const [isMobileView, setIsMobileView] = useState(false); // Ekran boyutuna g�re ayarlama

    var toggleClick = () => {
        var sidebarToggled = document.querySelectorAll(".pro-sidebar:not(.toggled)");
        var appWrapper = document.getElementsByClassName("app-wrapper");
        if (sidebarToggled && sidebarToggled.length > 0) {
            appWrapper[0].classList.add("toggled");
        } else {
            appWrapper[0].classList.remove("toggled");

        }
    }
    // Ekran geni�li�i 990px'den k���kse mobil g�r�n�m i�in sidebar'� gizlemek
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 990) {
                setIsMobileView(true);
                setSidebarVisible(false); // Ba�lang��ta gizli olsun
            } else {
                setIsMobileView(false);
                setSidebarVisible(true); // B�y�k ekranda g�r�n�r olsun
            }
        };

        // �lk y�klemede ve her yeniden boyutland�rmada kontrol eder
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Sidebar'� a��p kapatma
    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            var appWrapper = document.getElementsByClassName("app-wrapper");
            appWrapper.length > 0 && appWrapper[0].classList.remove("toggled");
        });
    }, [])

    return (
        <>
        { isMobileView && (
            <i onClick={toggleSidebar} className="fa-solid fa-bars sidebar-toggle-button">
            </i>
        )}
            {sidebarVisible && (
                <div className='bg-dark app-sidebar' style={{ display: 'flex', overflow: 'scroll initial' }}>
                    <CDBSidebar backgroundColor="#F6F6F6" textColor="#7E8299" maxWidth="220px" className="m-0">
                        <CDBSidebarHeader prefix={<i onClick={toggleClick} className="fa fa-angles-left fa-large" style={{ paddingLeft: '35px' }}></i>}>

                            <Link to="/index" className="text-decoration-none" style={{ color: 'inherit', textAlign: 'center' }}>
                                <img src={booby} alt="brand" width={150} ></img>
                            </Link>
                        </CDBSidebarHeader>

                        <CDBSidebarContent className="sidebar-content">
                            <CDBSidebarMenu>
                                <NavLink exact="true" to="/account/generalProfile" activeclassname="active">
                                    <CDBSidebarMenuItem className="m-0" style={{ backgroundColor: 'inherit' }} icon="user">General</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink exact="true" to="/account/uploadeds" activeclassname="active">
                                    <CDBSidebarMenuItem className="m-0" style={{ backgroundColor: 'inherit' }} icon="file-lines">Uploads</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink exact="true" to="/account/history" activeclassname="active">
                                    <CDBSidebarMenuItem className="m-0" style={{ backgroundColor: 'inherit' }} icon="book">History</CDBSidebarMenuItem>
                                </NavLink>
                                <NavLink exact="true" to="/account/invoices" activeclassname="active">
                                    <CDBSidebarMenuItem className="m-0" style={{ backgroundColor: 'inherit' }} icon="book-journal-whills">Invoices</CDBSidebarMenuItem>
                                </NavLink>

                            </CDBSidebarMenu>
                        </CDBSidebarContent>
                    </CDBSidebar>
                </div>
            )}
        </>
    )
}