const initialState = {
    data: null,
}
export const PurgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PURGE_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'PURGE_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        case 'PURGE_RESET':
            return initialState;
        default:
            return state;
    }
}

export default PurgeReducer;