const initialState = {
    data: null,
    busy:false,
};
export const FetchEditSharePostReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCHEDITSHAREPOST_BUSY':
            return {
                ...state,
                data: action.payload,
                busy:true,
            };
        case 'FETCHEDITSHAREPOST_SUCCESS':
            return {
                ...state,
                busy: false,
                data: action.payload,
            };
        case 'FETCHEDITSHAREPOST_FAILURE':
            return {
                ...state,
                busy: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default FetchEditSharePostReducer;