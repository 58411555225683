import '../styles/style.css';
import { Link } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';
import FilterComponent from './filterComponent';
import DataTable, { createTheme, Export, downloadCSV } from "react-data-table-component";
import { useSelector, useDispatch } from 'react-redux';
import { DeleteDocumentAction } from '../../actions/deleteDocument';
import { IndexAction } from '../../actions/IndexAction';
import { ButtonActionsFlag } from '../../actions/ButtonActionsFlag';
import { DeleteMoreThanOneDocumentAction } from '../../actions/deleteMoreThanOne';
import { getSharedWithMe } from '../../actions/getSharedWithMe';
import { DeleteMoreThanOneSharedAction } from '../../actions/deleteMoreThanOneShared';
createTheme("solarized", {
    text: {
        primary: "#268bd2",
        secondary: "#2aa198"
    },
    background: {
        default: "#002b36"
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF"
    },
    divider: {
        default: "#073642"
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)"
    }
});

export default function ListButtons(showData) {
    ;
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const ButtonActionsFlagReducer = useSelector(state => state.ButtonActionsFlagReducer);

    useEffect(() => {
        if (ButtonActionsFlagReducer) {
            setData(ButtonActionsFlagReducer.data);
        }
    }, [ButtonActionsFlagReducer]);

    const DeleteMoreThanOne = (event) => {
        event.preventDefault();
        var ids = '';
        if (data && data !== null) {
            for (var a = 0; a < data.length; a++) {
                ids = ids + data[a].model.id + ",";
            }
            dispatch(DeleteMoreThanOneDocumentAction(ids));
        }
    }

    const refresh = () => {
        dispatch(IndexAction());
    }

    const refreshSharedWithMe = () => {
        dispatch(getSharedWithMe());
    }

    const DeleteMoreThanOneSharedWithMe = (event) => {
        debugger;
        event.preventDefault();
        var ids = '';
        if (data && data !== null) {
            for (var a = 0; a < data.length; a++) {
                ids = ids + data[a].document.id + ",";
            }
            dispatch(DeleteMoreThanOneSharedAction(ids));
        }
    }

    return (
        <div className="listButtonGroup">
            {showData && showData.show
                && showData.data === "myDocuments" && 
                <>
                    <button type="button" onClick={refresh} className="play btn btn-new btn-inh btn-sm" title="Refresh">
                        <i className="fa fa-refresh"></i>
                        <span className="icon-text">Refresh</span>
                    </button>  
                    <button type="button" onClick={DeleteMoreThanOne} className="play btn btn-new btn-inh btn-sm" title="Remove Selected">
                        <i className="fa fa-times"></i>
                        <span className="icon-text">Remove Selected</span>
                    </button>  
                </>
            }
            {showData && showData.show
                && showData.data === "sharedWithMe" &&
                <>
                    <button type="button" onClick={refreshSharedWithMe} className="play btn btn-new btn-inh btn-sm" title="Refresh">
                        <i className="fa fa-refresh"></i>
                        <span className="icon-text">Refresh</span>
                    </button>
                    <button type="button" onClick={DeleteMoreThanOneSharedWithMe} className="play btn btn-new btn-inh btn-sm" title="Remove Selected">
                        <i className="fa fa-times"></i>
                        <span className="icon-text">Remove Selected</span>
                    </button>
                </>
            }
        </div>
    );
}
