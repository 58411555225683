import axios from 'axios';

export const GETUSERINFORMATION_SUCCESS = 'GETUSERINFORMATION_SUCCESS';
export const GETUSERINFORMATION_FAILURE = 'GETUSERINFORMATION_FAILURE';



export const getUserInformation = () => async (dispatch) => {
    
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post('/api/Main/New', null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETUSERINFORMATION_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: GETUSERINFORMATION_FAILURE,
            payload: errors,
        });

    };
}