const initialState = {
    data: null,
    busy:false
};
export const FetchEditShareMultiPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCHEDITMULTISHAREPOST_BUSY':
            return {
                ...state,
                data: action.payload,
                busy:true,
            };
        case 'FETCHEDITMULTISHAREPOST_SUCCESS':
            return {
                ...state,
                data: action.payload,
                busy: false
            };
        case 'FETCHEDITMULTISHAREPOST_FAILURE':
            return {
                ...state,
                busy: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default FetchEditShareMultiPostReducer;