import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EDITDOCUMENTPROP_SUCCESS = 'EDITDOCUMENTPROP_SUCCESS';
export const EDITDOCUMENTPROP_FAILURE = 'EDITDOCUMENTPROP_FAILURE';

export const EditDocumentPropAction = (id, Name, Description) => async (dispatch) => {
    try {

        const formDataI = {
            ID: id,
            Name: Name,
            Description: Description,
        }
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'));
        const token = tokenStorage.data.accessToken;
        await axios.post('/api/Main/Edit', formDataI, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('Document information saved successfully', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: EDITDOCUMENTPROP_SUCCESS,
                payload: response.data,
            });

        });
    } catch (errors) {
        toast.fail(errors.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: EDITDOCUMENTPROP_FAILURE,
            payload: errors.response,
        });
    };
}


