
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from "./modal";
import { PurgeAction } from '../../actions/purgeAction'

const PurgeDocumentModal = (props) => {
    const dispatch = useDispatch();
    const [IDs, setIDs] = useState(props.data);
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const [purgeDocumentTitle, setPurgeDocumentTitle] = useState("Purge Document");

    const PurgeAreYouSure = (event) => {
        event.preventDefault();
        dispatch(PurgeAction(IDs));
        setShowModal(false);
    }

    useEffect(() => {
        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true) {
                setShowModal(true);
            } else if (ShowModalFlagReducer.data === false) {
                setShowModal(false);
            }
        }
    }, [ShowModalFlagReducer])

    const style = {
        zIndex: '999',
        marginTop: '10px',
        position: 'absolute',
        backgroundColor: 'white',
        width: '500px',
        height: '200px',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign: 'left',
    }

    return (
        <div>
            <Modal onHide={() => setShowModal(false)} styleProps={style} onClose={() => setShowModal(false)} show={showModal} titleProps={purgeDocumentTitle}>
                <div>
                    <p>Are you sure about purging selected documents</p>
                </div>
                <div className="modal-footer-spec">
                    <button className="btn btn-secondary m-1 lift" onClick={() => setShowModal(false)}>No</button>
                    <button onClick={PurgeAreYouSure} className="btn btn-primary m-1 sharenow">Yes</button>
                </div>
            </Modal>
        </div>
    );
}

export default PurgeDocumentModal;