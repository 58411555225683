export const NOT_CHECKBOX = 'NOT_CHECKBOX';
export const ONE_CHECKBOX = 'ONE_CHECKBOX';
export const TWOORMORE_CHECKBOX = 'TWOORMORE_CHECKBOX';

export const ButtonActionsFlag = (data) => async (dispatch) => {
    if (!data) {
        dispatch({
            type: 'CLEAR_STATE',
        })
    } else if (data.selectedCount === 0) {
        dispatch({
            type: 'NOT_CHECKBOX',
            payload: data.selectedRows
        })
    } else if (0 < data.selectedCount && data.selectedCount < 2) {
        dispatch({
            type: 'ONE_CHECKBOX',
            payload: data.selectedRows
        })
    } else if (2 <= data.selectedCount) {
        dispatch({
            type: 'TWOORMORE_CHECKBOX',
            payload: data.selectedRows
        })
    }

}