export const SHOWMODALFLAG = 'SHOWMODALFLAG';
export const HIDEMODALFLAG = 'HIDEMODALFLAG';
export const RESETMODALFLAG = 'RESETMODALFLAG';
export const ShowModalFlag = (data) => async (dispatch) => {
    if (data) {
        dispatch({
            type: SHOWMODALFLAG,
            payload: data,
        });
    }else{
        dispatch({
            type: RESETMODALFLAG,
            payload: null,
        });
    } 
}

export const HideModalFlag = () => async (dispatch) => {
    dispatch({
        type: HIDEMODALFLAG,
        payload: false,
    });
}
