import React, { Component } from 'react';
import { Outlet } from 'react-router';
export class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <span style={{ color: "white" }} className="text-center">&copy;2024 Bymmy. All rights reserved.</span>
            </div>
        );
    }
}
