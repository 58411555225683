import { LineChart, Line, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Label } from 'recharts';
import React, { useEffect, useState, useContext } from 'react';
import ReactEcharts from "echarts-for-react"
import $ from 'jquery';
import { Link, useLocation } from 'react-router-dom';
import { ViewerAction } from '../../actions/viewer';
import { useDispatch, useSelector } from 'react-redux';
import CountUp from 'react-countup';
import { StatsAction } from '../../actions/statsAction';
import { Stats2Action } from '../../actions/Stats2Action';
import Modal from "./modal";
import { SharedFromMe } from '../../actions/sharedFromMe';

export default function Statistics(props) {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const location = useLocation();
    const id = props.id;
    const dispatch = useDispatch();
    const statsReducer = useSelector(state => state.statsReducer);
    const stats2Reducer = useSelector(state => state.stats2Reducer);
    const StatisticIdReducer = useSelector(state => state.StatisticIdReducer);
    const [showModal, setShowModal] = useState(false);
    const [sharedTableData, setSharedTableData] = useState([]);
    const sharedFromMeReducer = useSelector(state => state.SharedFromMeReducer);
    const [name, setName] = useState("");
    const [arrt, setArrt] = useState([]);


    const styleModal = {
        height: '650px'
    }
    var arrrt = [];
    useEffect(() => {
        dispatch(StatsAction(id));
        dispatch(Stats2Action(id));
    }, [props])

    useEffect(() => {
        if (StatisticIdReducer) {
            setName(StatisticIdReducer.name);
            setShowModal(StatisticIdReducer.show);
        }
    }, [StatisticIdReducer])


    useEffect(() => {
        if (statsReducer && statsReducer.data) {
            console.warn(statsReducer.data);
            var t = [];
            for (var i = 0; i < statsReducer.data.data.readsByDay.length; i++) {
                t[statsReducer.data.data.readsByDay[i].weekDay] = statsReducer.data.data.readsByDay[i].count;
            }
            for (var i = 0; i < 7; i++) {
                if (!t[i])
                    t[i] = 0;
            }
            setArrt(t);

        }
    }, [statsReducer])


    useEffect(() => {
        dispatch(SharedFromMe(id));
    }, []);

    useEffect(() => {
        if (sharedFromMeReducer && sharedFromMeReducer.data && sharedFromMeReducer.data.isSuccessful == true) {
            
        }

    }, [sharedFromMeReducer]);

    const options = {
        title: [
            {
                left: 'center',
                text: 'Read Pages'
            }
        ],
        legend: {
            show: false,
        },  
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'Page Read',
                type: 'pie',
                radius: [50, 100],
                center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 3
                },
                data: statsReducer?.data?.data?.totalReadsByPage

            }
        ]
    };
    
    const options1 = {
        title: [
            {
                left: 'center',
                text: 'Daily Opens vs Page Reads'
            }
        ],
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
        },
        legend: {
            data: ['Opens', 'Reads'],
            bottom:0
        },
        tooltip: {
            trigger: 'axis'
        },
            series: [
                {
                    name:'Opens',
                    data: arrt,
                    type: 'line',
                    smooth: true
                },
                {
                    name:'Reads',
                    data: arrt,
                    type: 'line',
                    smooth: true
                }
            ]
        
    }

    return (
        <div>
            <Modal title="Statistics" onLoad={() => setShowModal(true)} style={styleModal}  titleProps={'Statistics: ' +name} onClose={() => setShowModal(false)} show={showModal}>

                <div className="row">
                    <div className="col-md-4 text-center">
                        <h5>Total Views</h5>
                        <div className="statisticNumbers" style={{ fontSize: '3rem', fontWeight: 'bold' }}>
                            <CountUp end={statsReducer?.data?.data?.totalViews} duration={2}></CountUp>
                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                        <h5>Total Viewers</h5>
                        <div className="statisticNumbers" style={{fontSize:'3rem', fontWeight:'bold'}}>
                            <CountUp end={statsReducer?.data?.data?.uniqueViewers} duration={2}></CountUp>
                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                        <h5>Total Downloads</h5>
                        <div className="statisticNumbers" style={{ fontSize: '3rem', fontWeight: 'bold' }}>
                            <CountUp end={statsReducer?.data?.data?.totalDownloads} duration={2}></CountUp>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-6 text-center">                        
                        <ReactEcharts option={options1}></ReactEcharts>
                    </div>
                    <div className="col-md-6 text-center">
                        <ReactEcharts option={options}></ReactEcharts>
                    </div>

                </div>
                
         
            </Modal>
        </div>


        
    )
}
    /*
    <div className="row">
        <div className="col-md-12">
        Document created at 2023-01-03 14:50.
        </div>                    
    </div>
    */

        /*

               

                <div className="p-5">
                    <div id="container">
                        {data && (data !== null ? <ReactEcharts option={options}></ReactEcharts>
                            :
                            <div>pdf file has not been shared with anyone yet</div>
                        )}
                    </div>
                    
                </div>
                <div className="p-5">
                    <div id="container">
                        <ReactEcharts option={options1}></ReactEcharts>
                    </div>
                </div>
        <ResponsiveContainer>
                            <LineChart
                                type="monotone" 
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >

                                <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                                <XAxis dataKey="readTime" value='Temperature (C)' type="category"/>
                                <YAxis type="number"><Label style={{ FontSize: '14px !important' }} value='Number_Of_Reader' angle={-90} position='insideBottomLeft' /></YAxis>
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="number_of_reads_per_day_by_other_shared_users" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                        */