import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const FETCHEDITMULTISHAREPOST_SUCCESS = 'FETCHEDITMULTISHAREPOST_SUCCESS';
export const FETCHEDITMULTISHAREPOST_FAILURE = 'FETCHEDITMULTISHAREPOST_FAILURE';
export const FETCHEDITMULTISHAREPOST_BUSY = 'FETCHEDITMULTISHAREPOST_BUSY';
export const fetchEditMultiSharePost = ( id, email_address, multiExpireDate, allow_download_multi ) => async (dispatch) => {
    try {
        dispatch({
            type: FETCHEDITMULTISHAREPOST_BUSY,
            payload: null,
            busy:true
        })

        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const MultiSharePost = {
            ID: id,
            email: email_address,
            expireDate: multiExpireDate,
            allow_download: allow_download_multi,
        }
        await axios.post('/api/Main/FetchEditMultiSharePost', MultiSharePost, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('PDF document has been successfully shared !', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: FETCHEDITMULTISHAREPOST_SUCCESS,
                payload: response.data,
                busy: false
            });
        }) 
    } catch (error) {
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: FETCHEDITMULTISHAREPOST_FAILURE,
            payload: error.response.data.error.errors,
            busy: false
        });
    }

}

