const initialState = {
    data: null,
};
export const EditUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EDITUSER_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'EDITUSER_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default EditUserReducer;