
const initialState = {
    navigateConfirmFlag: false,
    error: null,
    success:null,
};

export const signInRedux = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGNIN_SUCCESS':
            return {
                ...state,
                error: null,
                navigateConfirmFlag: true,
                success: action.payload,
            };
        case 'SIGNIN_FAILURE':
            var errorMessage = [];
            if (action.payload.statusCode === 400) {
                var newMessage = action.payload.error.errors[0].split(";");
                newMessage.pop();
                const errorMessageNew = [...errorMessage, newMessage];
                return {
                    ...state,
                    navigateConfirmFlag: false,
                    error: errorMessageNew
                }

            } else if (action.payload.status === 400) {
                const errorMessageNew = [...errorMessage, Object.values(action.payload.errors)];
                return {
                    ...state,
                    navigateConfirmFlag: false,
                    error: errorMessageNew[0]
                }
            }

            return {
                ...state,
                 navigateConfirmFlag: false,
                error: action.payload

            };
        case 'SIGNIN_RESET':
            return initialState;
        default:
            return state;
    }
}

export default signInRedux;