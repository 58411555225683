const initialState = {
    data: null,

};
export const IndexReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INDEX_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'INDEX_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default IndexReducer;