const initialState = {
    data: null,
}
export const unDeleteDocumentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UNDELETEDOCUMENT_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'UNDELETEDOCUMENT_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        case 'UNDELETEDOCUMENT_RESET':
            return initialState;
        default:
            return state;
    }
}

export default unDeleteDocumentReducer;