import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Password } from '../../node_modules/@phosphor-icons/react/dist/index';

export const CHANGEPASSWORDINSIDE_SUCCESS = 'CHANGEPASSWORDINSIDE_SUCCESS';
export const CHANGEPASSWORDINSIDE_FAILURE = 'CHANGEPASSWORDINSIDE_FAILURE';
export const ChangePasswordInsideAction = (newPassword, currentPassword, confirmPassword) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const Password = {
            newPassword: newPassword,
            Password: currentPassword,
        }
        if (newPassword !== confirmPassword) {
            toast.error('new Password and New Password Confirmation does not match', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (newPassword === confirmPassword) {

            await axios.post("/api/Main/ResetPasswordDirectly", Password, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('Password changed successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: CHANGEPASSWORDINSIDE_SUCCESS,
                    payload: response.data,
                });
            })
        }
    } catch (errors) {
        toast.error(errors.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        })
        dispatch({
            type: CHANGEPASSWORDINSIDE_FAILURE,
            payload: errors,
        });

    };
}