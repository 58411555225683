import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const UNDELETEDOCUMENT_SUCCESS = 'UNDELETEDOCUMENT_SUCCESS';
export const UNDELETEDOCUMENT_FAILURE = 'UNDELETEDOCUMENT_FAILURE';
export const UNDELETEDOCUMENT_RESET = 'UNDELETEDOCUMENT_RESET';
export const unDeleteDocumentAction = (id) => async (dispatch) => {
    if (id === "") {
        dispatch({
            type: UNDELETEDOCUMENT_RESET,
            payload: null,
        });
    } else {
        try {
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
            const token = tokenStorage.data.accessToken;
            const ID = {
                ID: id,
            }
            await axios.post('/api/Main/UnDelete', ID, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('Document restored succesfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: UNDELETEDOCUMENT_SUCCESS,
                    payload: response.data,
                });
            });
        } catch (error) {
            toast.error(error.response.data.error.errors[0], {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: UNDELETEDOCUMENT_FAILURE,
                payload: error,
            });
        } 
    }

}

