const initialState = {
    data: null,
    name: null,
    show: false,
}
export const StatisticIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'STATISTICID':
            return {
                ...state,
                data: action.payload,
                name: action.name,
                show: action.showFlag,
            };
        case 'CLEAR_STATE_STATISTIC_ID':
            return initialState;
        default:
            return state;
    }
}

export default StatisticIdReducer;