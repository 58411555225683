import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const EDITDOCUMENT_SUCCESS = 'EDITDOCUMENT_SUCCESS';
export const EDITDOCUMENT_FAILURE = 'EDITDOCUMENT_FAILURE';
export const EditDocumentAction = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const ID = {
            ID: id,
        }
        await axios.post('/api/Main/GetEditDocument', ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('Success Notification !', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: EDITDOCUMENT_SUCCESS,
                payload: response.data,
            });
        })       
    } catch(error){
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: EDITDOCUMENT_FAILURE,
            payload: error,
        });
    }
   
}

