const initialState = {
    data: null,
    fetching:false
}
export const viewerFileStorageReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'VIEWERFILESTORAGE_SUCCESS':
            return {
                ...state,
                data: action.payload,
                fetching:false,
            };
        case 'VIEWERFILESTORAGE_FAILURE':
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        case 'VIEWERFILESTORAGE_FETCHING':
            return {
                ...state,
                fetching:true,
            }
        default:
            return state;
    }
}

export default viewerFileStorageReducer;