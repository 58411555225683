import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function DatePickerComponent({ changeMultiExpireDate }) {

    const [open, isOpen] = useState(false);
    const [preventReopen, setPreventReopen] = useState(false); // Tekrar a��lmay� engellemek i�in


    const nextMonth = () => {
        var now = new Date();
        var current = null;
        if (now.getMonth() == 11) {
            current = new Date(now.getFullYear() + 1, 0, 1);
        } else {
            current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        }
        return current;
    }


    const [startDate, setStartDate] = useState(nextMonth());


    useEffect(() => {

        const date = nextMonth();
        const dateString = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() % 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;

        changeMultiExpireDate(dateString);
    }, []);


    const changeDate = (date) => {
        setStartDate(date);
        const dateString = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() % 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;
        changeMultiExpireDate(dateString);
        setPreventReopen(true); // Yeniden a��lmay� engellemek i�in flag belirle
        setTimeout(() => setPreventReopen(false), 200); // 200ms sonra tekrar a��labilsin
    }

    return (
        <div style={{ width: '250px' }}>
            <label style={{ display: 'flex' }} >
                <DatePicker minDate={new Date()}
                    showDisabledMonthNavigation
                    className="form-control form-control-calender"
                    selected={startDate}
                    //onChange={changeDate}
                    onChange={(date) => {
                        changeDate(date);  // Tarihi de�i�tir
                    }}
                    onClickOutside={() => isOpen(false)} // D���na t�klan�nca kapanmas�n� sa�la
                    onSelect={() => isOpen(false)} // Tarih se�ilince kapanmas�n� sa�la
                    onInputClick={() => {
                        if (!preventReopen) {
                            isOpen(true); // E�er tekrar a��lmas� engellenmediyse a�
                        }
                    }} 
                    open={open} // A��k/kapal� durumunu kontrol et
                    showIcon
                />
                <div className="input-group-text" style={{ borderRadius: '0px 10px 10px 0px ' }}>
                    <i className="fa-solid fa-calendar-days"></i>
                </div>
            </label>
        </div>
    );
};