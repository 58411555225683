import React from 'react';

export default function TermsOfService() {

   return (
       <div className="terms-of-service">
           <h5>Terms and Conditions for Bymmy Share</h5>
           <p>
                By using Bymmy Share ("Service"), you are agreeing to be bound by the following terms and conditions (“Terms and Conditions”).
           </p>
           <p>
                Bymmy Share ("Company"), an Estonian-based entity, reserves the right to update and change these Terms and Conditions without notice. Users are encouraged to frequently check this page for any changes.
           </p>
           <p>
                Violation of any terms specified below may result in the termination of your account.
           </p>
           <h5>Account Terms</h5>
           <ol>
               <li>You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
               <li>You are responsible for all content posted and activity that occurs under your account.</li>
               <li>You may not use the Service for any illegal or unauthorized purpose. You must comply with all laws applicable in the EU and other local jurisdictions, including but not limited to copyright and data protection laws.</li>
               <li>During the signup process, you must provide a legal full name, valid email address, and any other information requested.</li>
               <li>Your login must not be shared and is unique to one person. You may create separate logins for different individuals.</li>
               <li>Automated account creation, including accounts registered by "bots", is prohibited.</li>
           </ol>
           <h5>Payment, Refunds, Upgrading, and Downgrading Terms</h5>
           <ol>
               <li>Bymmy Share offers various service tiers, including potential trial offers, which will be detailed during the account setup.</li>
               <li>Changes in service plans will be reflected in the next billing cycle. Downgrades during a billing cycle will not result in prorated refunds.</li>
               <li>Downgrading may result in a loss of features or capacity. The Company isn't liable for such losses.</li>
               <li>All listed fees are exclusive of any taxes, levies, or duties. As an EU-based entity, VAT or other applicable taxes may be added where required.</li>
               <li>Refunds are at the discretion of the Company and will be considered on a case-by-case basis.</li>
           </ol>
           <h5>Cancellation and Termination</h5>
           <ol>
               <li>You are solely responsible for canceling your account via the designated platform features.</li>
               <li>Upon cancellation, your content will be immediately inaccessible. Please ensure you backup your data regularly.</li>
               <li>Cancellation before the end of a billing cycle does not guarantee any refunds.</li>
               <li>The Company reserves the right to terminate or suspend accounts at its discretion.</li>
           </ol>
           <h5>Modifications to Service and Prices</h5>
           <ol>
               <li>The Company may modify or discontinue parts of the Service, either temporarily or permanently, with or without notice.</li>
               <li>Service pricing is subject to change with a 30-day notice.</li>
               <li>The Company won't be liable for modifications, price changes, or discontinuation of the Service.</li>
           </ol>

           <h5>Copyright and Content Ownership</h5>
           <ol>
               <li>All content posted on the Service must comply with EU copyright laws.</li>
               <li>Intellectual property rights of uploaded content remain with the user.</li>
               <li>The Company does not pre-screen content but can refuse or remove content at its discretion.</li>
           </ol>
           <h5>General Conditions</h5>
           <ol>
               <li>Service is provided “as is” and “as available”, without any warranties.</li>
               <li>Technical support is available via designated channels.</li>
               <li>The Service may utilize third-party services for various functionalities.</li>
               <li>Any misuse or attempt to disrupt the Service is prohibited.</li>
               <li>The Company isn’t liable for any damages or losses related to the use or inability to use the Service.</li>
               <li>The Company can disable accounts with usage that significantly exceeds average, after reaching out to the account owner.</li>
               <li>These Terms constitute the agreement between the user and the Company, superseding prior agreements.</li>
           </ol>

           <h5>Privacy and Data Protection</h5>
           <ol>
               <li>Bymmy Share adheres strictly to the General Data Protection Regulation (GDPR) and other relevant EU data protection regulations.</li>
               <li>All user data and content are treated with confidentiality. We will not sell, share, or rent your personal data to any third party or use your email address for unsolicited mail.</li>
               <li>Our privacy policy provides detailed explanations regarding how we handle your data.</li>
           </ol>

           <h5>Security</h5>
           <ol>
               <li>Bymmy Share prioritizes your security. We employ industry-standard protocols to protect your data and content.</li>
               <li>Despite our efforts, we cannot guarantee absolute security due to the inherent vulnerabilities of internet-based services.</li>
               <li>Users are encouraged to use strong, unique passwords and enable any additional security features provided.</li>
           </ol>

           <h5>Usage Restrictions</h5>
           <ol>
               <li>Bymmy Share is strictly for personal and professional use. Distributing harmful content such as malware, viruses, or phishing schemes is strictly prohibited.</li>
               <li>Use of the Service to store or distribute illicit materials, including but not limited to copyrighted content without permission, is strictly prohibited and may lead to account termination.</li>
           </ol>

           <h5>Intellectual Property</h5>
           <ol>
               <li>Users retain the intellectual property rights of their uploaded content but grant Bymmy Share a license to use, reproduce, and display such content only for the purpose of providing the Service.</li>
               <li>The Bymmy Share platform, its design, and any software used in connection with the Service are the property of Bymmy Share and may be protected by international copyright, trademarks, and other rights.</li>
           </ol>

           <h5>Limitation of Liability</h5>
           <ol>
               <li>Bymmy Share shall not be liable for any indirect, incidental, or consequential damages that may be incurred from the use or inability to use the Service.</li>
               <li>While Bymmy Share strives for accuracy, we do not guarantee the precision or completeness of any information within the Service.</li>
           </ol>

           <h5>Governing Law</h5>
           <ol>
               <li>These Terms and Conditions are governed by the laws of Estonia and any disputes arising shall be exclusively subject to the jurisdiction of the courts of Estonia.</li>
           </ol>

           <h5>Third-party Integrations</h5>
           <ol>
               <li>Bymmy Share may integrate with third-party services. While we aim to only integrate with reputable services, we are not responsible for the content, policies, or practices of these services.</li>
           </ol>

           <h5>Contact and Notifications</h5>
           <ol>
               <li>All official communication regarding the Service, changes to the Terms, or other essential information will be sent via the email associated with your Bymmy Share account.</li>
               <li>Users are responsible for keeping their contact information updated.</li>
           </ol>
           <p>
                By using Bymmy Share, you acknowledge and agree to these terms Terms and Conditions. It is recommended that users revisit these Terms and Conditions periodically to stay updated on any changes. For any questions or clarifications, please contact our support team. 
           </p>
       </div>
 
          
    )
}