const initialState = {
    data: null,

};

export const LinkEntryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LINKENTRY_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'LINKENTRY_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}


const initialStatePTG = {
    data: null,

};

export const PasswordlessTokenGenerationReducer = (state = initialStatePTG, action) => {
    switch (action.type) {
        case 'PTG_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'PTG_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}


const initialStatePLL = {
    data: null,

};

export const PasswordlessLoginReducer = (state = initialStatePLL, action) => {
    switch (action.type) {
        case 'PLL_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'PLL_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

