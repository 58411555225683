import React from 'react';

export default function PrivacyPolicy() {

    return (
        <div className="terms-of-service">

            <h4>Bymmy Share Privacy Policy</h4>
            <h5>Introduction</h5>
            <p>
                Bymmy Share respects your privacy and is committed to protecting your personal data. This Privacy Policy will provide you with information on how we collect and process your personal data, and your privacy rights.
            </p>
            <h5>Data We Collect</h5>
            <ul>
                <li><strong>Account Data</strong>: This includes your name, email address, password (encrypted and not directly accessible), and any other information you provide during the registration process.</li>
                <li><strong>Usage Data</strong>: Information about how you use our platform, such as uploaded PDFs, shared links, and interaction metrics.</li>
                <li><strong>Communication Data</strong>: Any communication content you send to us, whether through email, feedback forms, or other communication channels.</li>
            </ul>

            <h5>How We Use Your Data</h5>
            <ul>
                <li><strong>Providing the Service</strong>: To manage your account, process transactions, and offer customer support.</li>
                <li><strong>Improvement</strong>: To analyze platform usage, improve platform functionality, and enhance user experience.</li>
                <li><strong>Communication</strong>: To communicate with you about updates, changes, or other relevant matters concerning our service.</li>
            </ul>

            <h5>Data Retention</h5>
            <p>We retain your personal data only for as long as necessary to provide you with the Service and as described in this Privacy Policy. We also retain data for legal and audit purposes.</p>

            <h5>Data Protection</h5>
            <ul>
                <li>We use industry-standard methods to safeguard your data.</li>
                <li>We limit access to your personal data to those employees and contractors who have a business need-to-know.</li>
                <li>We have procedures in place to deal with any suspected data breach and will notify you and any applicable regulator where we are legally required to do so.</li>
            </ul>
            <h5>Sharing Your Data</h5>
            <ul>
                <li><strong>Service Providers</strong>: We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, or assist us in analyzing how our Service is used.</li>
                <li><strong>Legal Requirements</strong>: We may disclose your data if required by law or in response to valid requests by public authorities.</li>
            </ul>

            <h5>Your Rights</h5>
            Under the General Data Protection Regulation (GDPR), you have the following rights:
            <ul>
                <li><strong>Right to Access</strong>: You can request a copy of your personal data.</li>
                <li><strong>Right to Rectification</strong>: You can request that any incomplete or inaccurate data we hold about you be corrected.</li>
                <li><strong>Right to Erasure</strong>: Under certain conditions, you can request the deletion of your data.</li>
                <li><strong>Right to Restrict Processing</strong>: You can request to restrict the processing of your personal data.</li>
                <li><strong>Right to Object to Processing</strong>: You can object to Bymmy Share’s processing of your personal data.</li>
                <li><strong>Right to Data Portability</strong>: You can request to transfer your personal data to another organization or directly to you.</li>
            </ul>

            <h5>Cookies</h5>

            <p>We use cookies and similar tracking technologies to monitor and analyze our Service usage. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, some portions of our Service may not function properly.
            </p>
            <h5>Third-party Links</h5>

            <p>Our Service may include links to third-party websites, plugins, and applications. Clicking on those links may allow third parties to collect or share data about you. We are not responsible for their privacy statements.
            </p>

            <h5>Changes to This Privacy Policy</h5>

            <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page and, if significant, we'll provide more prominent notice.
            </p>
            <h5>Contacting Us</h5>

            <p>For any questions regarding this Privacy Policy, or to exercise any of your rights, please contact us through the provided contact methods on our platform.
            </p>

        </div>


    )
}