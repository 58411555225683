import React, { useState, useEffect } from 'react';
import Modal from "./modal";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getBalance } from "../../actions/getBalanceAction";

const CreditAccount = (props) => {
    
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const navigate = useNavigate();
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer.data.data);
    const getBalanceRedux = useSelector(state => state.getBalanceRedux)

    const [documentTitle, setDocumentTitle] = useState("Buy with Crypto");
    const [isFeedBack, setIsFeedBack] = useState(false);
    const onSendEmail = (event) => {
        event.preventDefault();        
        setShowModal(false);
        props.onClose();
    }
    
    
    
    const [prevBal, setPrevBal] = useState(undefined);
    useEffect(() => {
        if (getBalanceRedux && getBalanceRedux.data && getBalanceRedux.data.isSuccessful) {
            console.log( showModal, yourState, props.show, prevBal, getBalanceRedux.data.data.balance);
            if(prevBal === undefined || (prevBal != undefined && prevBal!= getBalanceRedux.data.data.balance))
            {
                if(prevBal != undefined && prevBal!= getBalanceRedux.data.data.balance){
                    console.log("balanced changed ", prevBal, getBalanceRedux.data.data.balance );
                    const dif = getBalanceRedux.data.data.balance - prevBal;
                    if(showModal ==true ) {
                        toast.success("Your balanced changed from "+prevBal+" to "+getBalanceRedux.data.data.balance+", changed "+dif+" credits. ", {
                            position: toast.POSITION.TOP_RIGHT
                        });             
                    }
                }

                setPrevBal(getBalanceRedux.data.data.balance);
            }
        }
    }, [getBalanceRedux]);

    useEffect(() => {
        dispatch(getBalance());
    }, []);



    const [yourState, setYourState] = useState(showModal);

    useEffect(() => {

        
        
        const intervalId = setInterval(() => {
            // Dispatch your function here
            dispatch(getBalance());

            // Check the condition to stop dispatching
            if (!yourState || !showModal) {
                clearInterval(intervalId);
            }
        }, 5000);

        // Clear the interval when the component is unmounted
        return () => {  clearInterval(intervalId); console.log("clearing out"); }
    }, [showModal, yourState]); 
    

    const onClickIsFeedback = (event) => {
        event.preventDefault();
        setIsFeedBack(event.target.value);
    }

    useEffect(() => {
        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true && ShowModalFlagReducer.show === "buycrypto") {
                setShowModal(true);
            } else if (ShowModalFlagReducer.data === false && ShowModalFlagReducer.show === "buycrypto") {
                setShowModal(false);
                props.onClose();
            }
        }
    }, [ShowModalFlagReducer])

    const cancelButton = (event) => {
        event.preventDefault();
        setShowModal(false);
        props.onClose();
    }


    useEffect( () => {

        if(showModal==false) {
            console.log("clearing balance");
            setPrevBal(undefined);
        }

    },[showModal]);

    const style = {
        zIndex: '999',
        marginTop: '10px',
        position: 'absolute',
        backgroundColor: 'white',
        maxWidth:'100%',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign:'center',
        width: '500px',
        color: 'black',
    }

    const copyToClipboard = async () => {
        try {
            const element = document.querySelector(".user-select-all");
            await navigator.clipboard.writeText(element.textContent);
            console.log("Text copied to clipboard!");
            toast.success('Wallet address copied to clipboard.', {
                position: toast.POSITION.TOP_RIGHT
            });
            // Optional: Display a success message to the user
        } catch (error) {
            console.error("Failed to copy to clipboard:", error);
            // Optional: Display an error message to the user
        }
    };


    const styleHeader = {

        backgroundColor: 'rgb(80, 205, 137)',
        color: 'white',
        fontSize:'2rem',
        fontWeight:'bold'
    }

    const buttonStyle = {
        display: 'flex',
        flexDirection: 'row-reverse'
    }



    console.warn(getUserInformationReducer);

    return (
        <div>
            <Modal onHide={() => setShowModal(false)} styleHeaderProps={styleHeader} styleProps={style} onClose={() => setShowModal(false)} show={showModal} titleProps={documentTitle}>            
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="form-group">
                            <p style={{'textAlign':'center'}}>
                                You can buy credits via crypto, Bymmy supports Polygon (MATIC) Blockchain. <br />
                                Please only send <b>"USDT" ( Tether )</b> to Bymmy Wallet Address.
                            </p>                            
                            <strong style={{ 'display':'block', 'cursor': 'pointer', 'textAlign': 'center' }} className='user-select-all' onClick={e => copyToClipboard() } >
                            0x00E07E2fBEb3848DF34B70459484cc46e2104c9B
                            </strong>
                            <p style={{'textAlign':'center'}}>
                                $1 USDT equals to 100 Credits.
                            </p>

                            <p style={{'textAlign':'center'}}>
                                Your registered wallet address is <b></b>, if you are sending different wallet from your registered wallet address, please first update your wallet address.
                            </p>
                            { getUserInformationReducer.ethWalletAddress &&
                                <p style={{ 'textAlign': 'center' }}>Please send from your registered wallet address
                                    <br /><button className="btn btn-success">
                                        <i className="fa-solid fa-wallet"></i>
                                        <strong style={{ 'display': 'block', 'cursor': 'pointer', 'textAlign': 'center' }} >{getUserInformationReducer.ethWalletAddress}</strong>
                                    </button>
                                    <br />
                                    to Bymmy wallet address. System will top up your credits when payment received.</p>
                            }
                            {
                                !getUserInformationReducer.ethWalletAddress &&
                                <p className="bg-danger text-white text-center">
                                <i className="p-2 fa-solid fa-bell"></i>
                                        <Link style={{'color':'white'}} to="/account/generalProfile" >
                                            Please set your wallet address before sending funds.
                                        </Link>
                                </p>
                            }
                            </div>
                        
                        <div className="form-group mt-3">
                            <p>Please wait while we are waiting your payment, Bymmy will notify you. Please do not close this popup. Depends on Polygon Network delay on process, it may take up 5 minutes.</p>
                        </div>
                        Current balance is : <b>{prevBal}</b>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-m-4" style={buttonStyle}>
                       
                        <button onClick={cancelButton} className="btn btn-danger m-3 lift text-white">Close</button>
                        
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default CreditAccount;