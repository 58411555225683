/* eslint-disable react-hooks/rules-of-hooks */
import { Portal } from 'react-portal';
import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ShowModalFlag } from '../../actions/showModalFlag';
export default function MenuModel(props) {
    const dispatch = useDispatch();
    if (!props.show) {
        return null;    
    }

    return (
        <Portal>
            {props.mainMenu ?
                <div className="modal-white no-border" onClick={props.onClose}>
                    {props.children}
                </div> :
                <div className="modal no-border" onClick={props.onClose}>
                    {props.children}
                </div>
            }
        </Portal>
    );
}
