import '../styles/style.css';
import React, { useEffect } from 'react';
import RecycleBin from './recycleBin';
import NavigationBar from './sideBarMenu';
import NavigationNames from "./navigationNames";
import ButtonGroup from './buttonGroup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function RecycleBinPage() {
    useEffect(() => {
        //dispatch(PurgeAction(""));
        //dispatch(unDeleteDocumentAction(""));
    },[])
    return (
        <div className="mainDivRsT">
            <NavigationBar />
            <ToastContainer />
            <section className='app-wrapper w-100 me-3 mb-3'>   
                <div className='currentMenuItem'>
                    <NavigationNames MenuName="Recycle Bin" />
                </div>

                <div style={{ width: '100%' }}>
                    <div id="recyclebin row card borderNoneCard">
                        
                        <div className="row card borderNoneCard listPage-mainDiv">
                            <ButtonGroup typeOfButtons="recycleBin" />

                            
                            <div className="tab-content mt-4">
                                <div className="tab-pane active" id="RecycleBin">
                                    <RecycleBin />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>          
            </section>
        </div>

    );
}

//tab yap�s�
/*
<div className="card-header">
    <ul className="nav nav-tabs card-header-tabs" data-bs-tabs="tabs">
        <li className="nav-item">
            <a className="nav-link active" aria-current="true" data-bs-toggle="tab" href="#recycleBin">RecycleBin</a>
        </li>
    </ul>
</div>
*/
//<div className="alert alert-secondary" role="alert">Items in trash are deleted forever after 30 days</div>
//  onRowClicked={handleRowClicked}