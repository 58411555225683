import axios from 'axios';

export const REGISTERCONFIRMATION_SUCCESS = 'REGISTERCONFIRMATION_SUCCESS';
export const REGISTERCONFIRMATION_FAILURE = 'REGISTERCONFIRMATION_FAILURE';


export const registerConfirmationAction = (codeId, userId) => async (dispatch) => {
    try {
        const confirmed = {
            code: codeId,
            userId: userId,
        }
        const response = await axios.post("/api/Main/ConfirmEmail", confirmed);

        dispatch({
            type: REGISTERCONFIRMATION_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: REGISTERCONFIRMATION_FAILURE,
            payload: errors,
        });

    };
}
