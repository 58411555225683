import axios from 'axios';

export const REFRESHTOKEN_SUCCESS = 'REFRESHTOKEN_SUCCESS';
export const REFRESHTOKEN_FAILURE = 'REFRESHTOKEN_FAILURE';

export const refreshTokenAction = (reftoken) => async (dispatch) => {
    try {
        const reftok = {
            refreshToken: reftoken
        };

        const response = await axios.post('/api/Auth/RefreshToken', reftok, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        //authenticated redirect home page
        dispatch({
            type: REFRESHTOKEN_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        //not authenticated give error
        dispatch({
            type: REFRESHTOKEN_FAILURE,
            payload: errors.response.data.error.errors[0],
        });

    };
}
