import React, { Component } from 'react';
import NavigationBar from './sideBarMenu';
import '../styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import '@fortawesome/fontawesome-free/css/all.min.css';
import analysisbooby from '../../images/analysisbobby.png'
import { Link } from 'react-router-dom';
export default function New(props) {

    return (  
    <div style={{display: 'flex'}}>
        <NavigationBar />
            <section className="py-5  container">
                <div className="row">
                    <div className="col-6">
                    <form action="/Main/New" enctype="multipart/form-data" id="newform" method="post">            
                        <h2>Share Content</h2>
                            <div className="form-horizontal">
                            <hr></hr>
                                <div className="mb-3">
                                <label for="formFile" className="form-label">Only PDF file is allowed</label>
                                    <input className="form-control" type="file" id="formFile" name="formFile" required="" accept="application/pdf" />
                                    <span className="text-warning field-validation-valid" data-valmsg-for="formFile" data-valmsg-replace="true" required="required"></span>
                            </div>


                            <div className="form-group mb-3">
                                    <label className="control-label form-label col-md-2" for="Name">Name</label>

                                    <input className="form-control text-box single-line" data-val="true" data-val-required="The Name field is required." id="Name" name="Name" required="required" type="text" value="" />
                                    <span className="text-warning field-validation-valid" data-valmsg-for="Name" data-valmsg-replace="true" required="required"></span>

                            </div>

                                <div className="form-group mb-3">
                                    <label className="control-label form-label col-md-2" for="Description">Description</label>
                                    <textarea className="form-control min-height-100" data-val="true" data-val-required="The Description field is required." id="Description" name="Description" required="required"></textarea>
                                    <span className="text-warning field-validation-valid" data-valmsg-for="Description" data-valmsg-replace="true"></span>

                            </div>

                                <div className="form-group mb-3">
                                    <div className="col-md-offset-2 col-md-10">
                                        <button id="btnsave" className="button  btn btn-success my-2">
                                            <span className="spinner-border spinner-border-sm" style={{display:"none"}} role="status" aria-hidden="true"></span>
                                            <i className="bi bi-plus-square"></i> Upload Content
                                    </button>
                                </div>
                            </div>
                     </div>
                    <input name="__RequestVerificationToken" type="hidden" value="CfDJ8NQ2Retm3ZNBptZYaTQng8mlOOcEgOOiXD4ZO63Ml1Pp1_ut6OnPpJOkANKV7RV3aJrQrw9LZOm-_8_HXEmgsAv93vsJ3Qd8QZOkwxi7BMbPuI1BRRkkbhfxXITrk7M4liZ-mdtDlDkxPl2ANFFZqEOvz0fzXeZl2g5FGxjDVqppbOfgV43PtiqXKdZADLqYwg"/>
                </form>
            </div>
                    <div className="col-6">
                <h2>Please fill the form completely and click Create to start creating your first document! Only PDF accepted for now.
                </h2>
                <div style={{position:"relative", textAlign:"center", padding:"10px"}}>
                    <div style={{position:"absolute",marginLeft:"auto",marginRight:"auto",textAlign:"center"}}>
                        <img  style={{padding:"10px" }} src={analysisbooby} alt="brand" width={400} height={400}></img>  
                    </div>
                            <div className="form-group mb-3 " style={{ margin: "auto",width: "200px",height: "200px",position: "relative",display:"none"}} id="pgp">                    
                        <svg viewBox="0 0 100 100" style={{display: "block", width: "100%"}}><path d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96" stroke="#eee" stroke-width="1" fill-opacity="0"></path><path d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96" stroke="rgb(121,185,60)" stroke-width="4" fill="rgba(0, 0, 0, 0.5)" ></path></svg>
                                <div className="progressbar-text" style={{position: "absolute", left: "50%", top: "50%", padding: "0px", margin: "0px", transform: "translate(-50%, -50%)", color: "rgb(121, 185, 60)", fontFamily: "Poppins, Rubik, Raleway, Helvetica, sans-serif", fontSize: "2rem", fontWeight: "bold"}}>
                    </div>
                </div>            
            </div>        
        </div>
        
                    <div className="mt-3">
                        <Link to='/index' className="btn btn-primary">
                            <i className="bi bi-backspace"></i> Back to Home
            </Link>    
        </div>
</div>
    </section>
</div>
    )
}