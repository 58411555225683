export const NOT_RECYCLEBIN_CHECKBOX = 'NOT_RECYCLEBIN_CHECKBOX';
export const ONEORMORE_CHECKBOX = 'ONEORMORE_CHECKBOX';
export const CLEAR_STATE_FOR_RECYCLEBIN_CHECKBOX = 'CLEAR_STATE_FOR_RECYCLEBIN_CHECKBOX';
export const RecyclebinButtonActionFlag = (data) => async (dispatch) => {
    if (!data) {
        dispatch({
            type: 'CLEAR_STATE_FOR_RECYCLEBIN_CHECKBOX',
            payload: null,
        })
    } else if (data.selectedCount === 0) {
        dispatch({
            type: 'NOT_RECYCLEBIN_CHECKBOX',
            payload: data.selectedRows
        })
    } else if (0 < data.selectedCount) {
        dispatch({
            type: 'ONEORMORE_CHECKBOX',
            payload: data.selectedRows
        })
    }

}