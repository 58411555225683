export const EDIT_FILE_DATA = 'EDIT_FILE_DATA';
export const EDIT_FILE_RESET = 'EDIT_FILE_RESET';
export const EditFileAction = (data) => async (dispatch) => {
    if (!data) {
        dispatch({
            type: 'EDIT_FILE_RESET',
            payload: null,
        })
    } else{
        dispatch({
            type: 'EDIT_FILE_DATA',
            payload: data,
        })
    }

}