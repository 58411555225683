import React, { Component, useState, useRef, useEffect } from 'react';
import '../styles/style.css';
import { useNavigate, Link } from 'react-router-dom';
import Overlay from 'react-bootstrap/Overlay';
import { useSelector, useDispatch } from 'react-redux';
import booby from '../../images/booby-72.png';
import boobyhead from '../../images/istockphoto-989632322-170667a.jpg';
import { LogoutAction } from '../../actions/logoutAction';
import { ShowModalFlag } from '../../actions/showModalFlag';
import { getUserInformation } from '../../actions/getUserInformation';
import MenuModel from './menuModel';
import { UserCircle } from "@phosphor-icons/react";
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

export default function MainMenuDropdown() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userDoc, setUserDoc] = useState(JSON.parse(localStorage.getItem('user')));
    const [userDocImg, setUserDocImg] = useState();
    const [userFirstCharacter, setUserFirstCharacter] = useState("");
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer);
    const [image, setImage] = useState(false);
    const [profilImage, setProfilImage] = useState();
    const storeUserImageReducer = useSelector(state => state.storeUserImageReducer);
    const DeleteUserImageReducer = useSelector(state => state.DeleteUserImageReducer);
    
    const logOutClick = () => {
        console.log("session");
        dispatch(LogoutAction());
        sessionStorage.clear();
        localStorage.clear();
        navigate('/home');
    }

    useEffect(() => {
        dispatch(getUserInformation());
        setProfilImage(userDocImg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setProfilImage(userDocImg);
    }, [userDocImg])


    useEffect(() => {
        if (storeUserImageReducer && storeUserImageReducer.data) {
            dispatch(getUserInformation());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeUserImageReducer])

    useEffect(() => {

        if (userDoc && userDoc !== null) {

            var names = userDoc.userName.split(' ');
            var sum = "";
            for (var a = 0; a < names.length; a++) {
                sum = sum + names[a][0].toUpperCase();
            }
            setUserFirstCharacter(sum);
        }
    }, [userDoc]);

    useEffect(() => {
        if (getUserInformationReducer && getUserInformationReducer.data && getUserInformationReducer.data.isSuccessful) {
            setUserDocImg(JSON.parse(JSON.stringify(getUserInformationReducer.data.data.image)));
            setUserDoc(JSON.parse(JSON.stringify(getUserInformationReducer.data.data)))
            debugger;
        }
    }, [getUserInformationReducer])

    useEffect(() => {
        if (DeleteUserImageReducer && DeleteUserImageReducer.data && DeleteUserImageReducer.data.isSuccessful) {
            setUserDocImg(JSON.parse(JSON.stringify(DeleteUserImageReducer.data.data.image)));
        }
    }, [DeleteUserImageReducer])

    const [showt, setShowt] = useState(false);
    const target = useRef(null);

    const handleImageChange = (e) => {
        dispatch(ShowModalFlag(true));
        setImage(true);
    }

    return (
        <div className="d-flex float-end">
            <div>
                <a ref={target} onClick={() => setShowt(!showt)}>
                        {profilImage ?
                            <img style={{ borderRadius: '50%' }} alt="main" src={'data:image/jpeg;base64,' + profilImage} width={30} height={30} />
                        :
                         <div className="mainCurrentAccountPicture" >
                            <div className="userCharacterStyle">
                                {userFirstCharacter}
                            </div>
                        </div>
                        }  
                </a>
                <MenuModel onHide={() => setShowt(!showt)} baseMenu={true} onClose={() => setShowt(!showt)} show={showt} mainMenu={true}>
                    <Overlay target={target.current} show={showt} placement="bottom">
                    {({
                        placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props
                    }) => (
                        <div {...props}
                            style={{
                                zIndex: '9999',
                                marginTop: '8.5px',
                                position: 'absolute',
                                backgroundColor: 'white',
                                padding: '2px 10px',
                                width: '320px',
                                height: '150px',
                                padding: '10px',
                                boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
                                borderRadius: 3,
                                marginLeft: '3px',
                                ...props.style,


                            }}
                        >
                           
                            <div>
                                <div className="d-flex">
                                    <img src={booby} alt="brand" height={30}></img>
                                    <Link className='btn d-block logout' to='/login' onClick={logOutClick}><i className="fa fa-arrow-right-from-bracket"></i>&nbsp;Log Out</Link>
                                </div>
                                {userDoc && userDoc !== null &&
                                    
                                        <div className="AccountPicture d-flex">

                                        <Link className='currentAccountPicture' onClick={handleImageChange} to={{
                                                pathname: `/account/generalProfile`,
                                        }} state={{ changePicture: image }}>
                                            {profilImage ?
                                                <div style={{ marginLeft: '-1px' }}>
                                                    <img style={{ borderRadius: '50%' }} src={'data:image/jpeg;base64,' + profilImage} width="90" height="90"/>
                                                </div>
                                                :
                                                <>
                                                    <div className="userCharacterStyle">
                                                        {userFirstCharacter}
                                                    </div>
                                                </>
                                            }
                                            </Link>
                                        <div className="d-block mainMenuUser">
                                            <>
                                                <div className="mb-4">
                                                        <div><strong>{userDoc.email}</strong></div>
                                                        <div>{userDoc.firstName}&nbsp;{userDoc.lastName}</div>
                                                </div>
                                            </>
                                                <div>
                                                    <div className="d-flex mt-1">
                                                        <Link className='w-100 d-block mainMenuLink' state={{ name: "account" }} to='/account/generalProfile'>Manage Your Account</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                }
                                </div>
                            
                        </div>
                    )}
                    </Overlay>
                </MenuModel>
            </div>
        </div>
    )
}
/*
<Link className='w-100 d-block mainMenuLink' to='/account/generalProfile'>Manage Your Profile</Link>
<UserCircle style={{ color: 'rgb(40, 168, 226)' }} size={26} />
*/
/*
 <div><strong>{userDoc.userName}</strong></div>
*/
