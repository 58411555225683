import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PURGE_SUCCESS = 'PURGE_SUCCESS';
export const PURGE_FAILURE = 'PURGE_FAILURE';
export const PURGE_RESET = 'PURGE_RESET';
export const PurgeAction = (ids) => async (dispatch) => {
    if (ids === "") {
        dispatch({
            type: PURGE_RESET,
            payload: null,
        });
    } else {
        try {
            const inputIDs = {
                IDs: ids,
            }
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
            const token = tokenStorage.data.accessToken;

            await axios.post("/api/Main/Purge", inputIDs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                dispatch({
                    type: PURGE_SUCCESS,
                    payload: response.data,
                });
                toast.success('Documents purged successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
            })

        } catch (errors) {
            toast.error(errors.response.data.error.errors[0], {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: PURGE_FAILURE,
                payload: errors,
            });
        }
    };
}

