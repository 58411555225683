import axios from 'axios';

export const GETSTATSGROUPDATE_SUCCESS = 'GETSTATSGROUPDATE_SUCCESS';
export const GETSTATSGROUPDATE_FAILURE = 'GETSTATSGROUPDATE_FAILURE';

export const Stats2Action = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const documentId = {
            ID: id,
        }
        const response = await axios.post("/api/Main/Stats2", documentId, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETSTATSGROUPDATE_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: GETSTATSGROUPDATE_FAILURE,
            payload: errors,
        });

    };
}