import { combineReducers } from "redux";
import loginRedux from '../reducers/loginRedux';
import signInRedux from '../reducers/signInRedux';
import getSharedWithMeRedux from './getSharedWithMeRedux';
import newPdfRedux from './newPdfRedux';
import getMyDocumentRedux from './getDocumentRedux';
import forgetPasswordRedux from './forgetPasswordRedux';
import sendVerificationEmailRedux from './sendVerificationEmailRedux';
import forgetPasswordFormRedux from './forgetPasswordFormRedux';
import registerConfirmationReducer from './registerConfirmReducer';
import getBalanceRedux from './getBalanceRedux';
import EditDocumentReducer from './editDocumentReducer';
import HoldValReducer from "./holdValReducer";
import getDocumentWithIDReducer from "./getDocumentWithIDReducer";
import FetchEditSharePostReducer from './fetchEditSharePost';
import FetchEditShareMultiPostReducer from './FetchEditSharePostMultiple';
import DeleteDocumentReducer from './deleteDocumentReducer';
import getDeletedByMeReducer from './getDeletedDocument';
import unDeleteDocumentReducer from './unDeleteDocument';
import getUserInformationReducer from './getUserInformationReducer';
import IndexReducer from './IndexReducer';
import ButtonActionsFlagReducer from './buttonActionsFlagReducer';
import DeleteMoreThanOneDocumentReducer from './deleteMoreThanOneReducer';
import { EditDocumentPropReducer } from './EditDocumentProps';
import sendFeedBackEmailReducer from './sendFeedBackEmailReducer';
import viewerReducer from './viewerReducer';
import viewerFileStorageReducer from './viewerFileStorageReducer';
import refreshTokenReducer from './refreshTokenReducer';
import { LinkEntryReducer, PasswordlessTokenGenerationReducer } from './LinkEntryReducer';
import statsReducer from './statsReducer';
import stats2Reducer from "./Stats2Reducer"; 
import ModalFlagReducer from './modalFlagReducer';
import EditUserReducer from './editUserReducer';
//import EditUserWalletReducer from './editUserWalletReducer';
import SplashScreenReducer from './SplashScreenReducer'
import PurgeReducer from './purgeReducer';
import SelectedCheckboxReducer from './selectedCheckboxReducer';
import UnDeleteMoreThanOneReducer from './unDeleteMoreThanOneReducer';
import SharedFromMeReducer from './sharedFromMeReducer';
import GetSharedFromMeAllReducer from './getSharedFromMeAllReducer';
import GetUserLedgerReducer from "./getUserLedgerReducer";
import StatisticIdReducer from "./statisticIdReducer"
import ShowModalFlagReducer from "./ShowModalFlagReducer";
import DownloadFilesReducer from "./downloadFilesReducer";
import IsAuthorizeDocumentReducer from "./isAuthorizeDocumentReducer";
import RecycleBinButtonReducer from './recycleBinButtonReducer';
import storeUserImageReducer from './storeUserImageReducer';
import DeleteUserImageReducer from './deleteUserImageReducer';
import ChangePasswordInsideReducer from './changePasswordInsideReducer';
import SendReportEmailReducer from './sendReportEmailReducer';
import PrintPDFFileReducer from "./PrintPDFFileReducer";
import { PasswordlessLoginReducer }  from "./LinkEntryReducer";
import EditFileReducer from "./EditFileReducer";
import DeleteMoreThanOneSharedDocumentReducer from "./deleteMoreThanOneSharedReducer.js";
export const rootReducer = combineReducers({
    loginRedux: loginRedux,
    signInRedux: signInRedux,
    getSharedWithMeRedux: getSharedWithMeRedux,
    getMyDocumentRedux: getMyDocumentRedux,
    newPdfRedux: newPdfRedux,
    forgetPasswordRedux: forgetPasswordRedux,
    sendVerificationEmailRedux: sendVerificationEmailRedux,
    forgetPasswordFormRedux: forgetPasswordFormRedux,
    registerConfirmationReducer: registerConfirmationReducer,
    getBalanceRedux: getBalanceRedux,
    EditDocumentReducer: EditDocumentReducer,
    HoldValReducer: HoldValReducer,
    getDocumentWithIDReducer: getDocumentWithIDReducer,
    FetchEditSharePostReducer: FetchEditSharePostReducer,
    FetchEditShareMultiPostReducer: FetchEditShareMultiPostReducer,
    DeleteDocumentReducer: DeleteDocumentReducer,
    getDeletedByMeReducer: getDeletedByMeReducer,
    unDeleteDocumentReducer: unDeleteDocumentReducer,
    getUserInformationReducer: getUserInformationReducer,
    IndexReducer: IndexReducer,
    ButtonActionsFlagReducer: ButtonActionsFlagReducer,
    DeleteMoreThanOneDocumentReducer: DeleteMoreThanOneDocumentReducer,
    EditDocumentPropReducer: EditDocumentPropReducer,
    sendFeedBackEmailReducer: sendFeedBackEmailReducer,
    viewerReducer: viewerReducer,
    viewerFileStorageReducer: viewerFileStorageReducer,
    refreshTokenReducer: refreshTokenReducer,
    statsReducer: statsReducer,
    LinkEntryReducer: LinkEntryReducer,
    stats2Reducer: stats2Reducer,
    ModalFlagReducer: ModalFlagReducer,
    EditUserReducer: EditUserReducer,
    //EditUserWalletReducer: EditUserWalletReducer,
    SplashScreenReducer: SplashScreenReducer,
    PurgeReducer: PurgeReducer,
    SelectedCheckboxReducer: SelectedCheckboxReducer,
    UnDeleteMoreThanOneReducer: UnDeleteMoreThanOneReducer,
    SharedFromMeReducer: SharedFromMeReducer,
    GetSharedFromMeAllReducer: GetSharedFromMeAllReducer,
    GetUserLedgerReducer: GetUserLedgerReducer,
    StatisticIdReducer: StatisticIdReducer,
    ShowModalFlagReducer: ShowModalFlagReducer,
    DownloadFilesReducer: DownloadFilesReducer,
    IsAuthorizeDocumentReducer: IsAuthorizeDocumentReducer,
    RecycleBinButtonReducer: RecycleBinButtonReducer,
    storeUserImageReducer: storeUserImageReducer,
    DeleteUserImageReducer: DeleteUserImageReducer,
    ChangePasswordInsideReducer: ChangePasswordInsideReducer,
    SendReportEmailReducer: SendReportEmailReducer,
    PrintPDFFileReducer: PrintPDFFileReducer,
    PasswordlessLoginReducer: PasswordlessLoginReducer,
    PasswordlessTokenGenerationReducer: PasswordlessTokenGenerationReducer,
    EditFileReducer: EditFileReducer,
    DeleteMoreThanOneSharedDocumentReducer: DeleteMoreThanOneSharedDocumentReducer
    //coming soon...

})  