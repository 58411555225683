import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/login.css';
import { registerConfirmationAction } from '../../actions/registerConfirmationAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import booby from '../../images/Logo_removebg.png';
export function RegisterConfirmation(){
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [code, setCode] = useState('');
    const success = useSelector(st => st.registerConfirmationReducer.success);


    useEffect(() => {
        let redirectTimeout: 0;
        if (success) {
            redirectTimeout = setTimeout(() => {
                navigate("/login");
            }, 3000);
        }
        return () => {
            clearTimeout(redirectTimeout);
        }

    }, [success]);

    useEffect(() => {
        if (window.location.search.indexOf('userId') > -1) {
            var userId = window.location.search.split('?userId=')[1];
            var tmp = window.location.search.split('?userId=')[0];
            if (tmp.indexOf('token') > -1) {
                var code = tmp.split('?token=')[1];
            } 
            dispatch(registerConfirmationAction(code, userId));
        }
    }, []);



    return (
        <div className="row AuthTL">
            <div className="col-md-4">
                <p style={{textAlign: 'center'}} className="confirm">
                    Your Mail is confirmed, you will be redirected to login page.
                </p>
            </div>
        </div>
        );
    
}
   