
export const getUserInformationReducer = (state = null, action) => {
    switch (action.type) {
        case 'GETUSERINFORMATION_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'GETUSERINFORMATION_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default getUserInformationReducer;