import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DELETEDOCUMENT_SUCCESS = 'DELETEDOCUMENT_SUCCESS';
export const DELETEDOCUMENT_FAILURE = 'DELETEDOCUMENT_FAILURE';
export const DeleteDocumentAction = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const ID = {
            ID: id,
        }
        await axios.post('/api/Main/Delete', ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function(response){
            toast.success('Document recycle successfully', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: DELETEDOCUMENT_SUCCESS,
                payload: response.data,
            });
        })

    } catch (error) {
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        })
        dispatch({
            type: DELETEDOCUMENT_FAILURE,
            payload: error,
        });
    }

}

