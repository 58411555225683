const initialState = {
    recycle: false,
    purge: false,
    data: null,
};

export const RecycleBinButtonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'NOT_RECYCLEBIN_CHECKBOX':
            return {
                ...state,
                recycle: false,
                purge: false,
                data: action.payload,
            };
        case 'ONEORMORE_CHECKBOX':
            return {
                ...state,
                recycle: true,
                purge: true,
                error: action.payload
            };
        case 'CLEAR_STATE_FOR_RECYCLEBIN_CHECKBOX':
            return initialState;
        default:
            return state;
    }
}
export default RecycleBinButtonReducer;