import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const UNDELETEMORETHANONEDOCUMENT_SUCCESS = 'UNDELETEMORETHANONEDOCUMENT_SUCCESS';
export const UNDELETEMORETHANONEDOCUMENT_FAILURE = 'UNDELETEMORETHANONEDOCUMENT_FAILURE';
export const UnDeleteMoreThanOne = (ids) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const ID = {
            IDs: ids,
        }
        await axios.post('/api/Main/unDeleteMoreThanOne', ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('Documents restored succesfully', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: UNDELETEMORETHANONEDOCUMENT_SUCCESS,
                payload: response.data,
            });
        })
    } catch (error) {
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: UNDELETEMORETHANONEDOCUMENT_FAILURE,
            payload: error,
        });
    }

}

