import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { Component, useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import booby from './images/Logo_removebg.png';
import boobybird from './images/istockphoto-989632322-170667a.jpg';
import MainMenuDropdown from './components/Main/mainMenuDropdown';
import { ReactComponent as Ninedot } from './icons/dial-pad.svg';
import MenuModel from './components/Main/menuModel';

export default function BaseNavbar() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [authorizeFlag, setAuthorizeFlag] = useState(false);
    const loginRedux = useSelector(state => state.loginRedux);
    const onClickShowButton = (event) => {
        event.preventDefault();
        setShow(!show);
    }
    useEffect(() => {
        if (localStorage.getItem('user') !== null) {
            setAuthorizeFlag(true);
        } else if (localStorage.getItem('user') === null) {
            setAuthorizeFlag(false);
        }
        if (loginRedux.isAuthenticated) {
            setAuthorizeFlag(true);
        }
        /*if (localStorage.getItem('AccessToken') !== null) {
            setAuthorizeFlag(true);
        } else if (localStorage.getItem('AccessToken') === null) {
            setAuthorizeFlag(false);
        }*/
    }, [loginRedux]);

    const PDFShare = (event) => {
        event.preventDefault();
        setShow(false);
        navigate('/index');
    }
    return (
        <div className='baseNavbar'>
            <nav className="navbar" >
                <div className="container-fluid">
                    <button onClick={onClickShowButton} className="btn btn-light mb-2">
                        <Ninedot style={{ width: '22px' }} />
                    </button>
                    <MenuModel onHide={() => setShow(!show)} baseMenu={true} onClose={() => setShow(!show)} show={show}>
                        <div
                            className="BaseNineDotMenu">
                            <div>
                                <div className='w-100  p-3'>
                                    <button onClick={onClickShowButton} className="btn btn-light">
                                        <Ninedot style={{ width: '22px' }} />
                                    </button>
                                    <img className="float-end" src={booby} alt="brand" width={100}></img>
                                </div>

                                <div className="p-4 mt-4" style={{ display: 'flex' }}>
                                    <div style={{ display: 'block' }}>
                                        <h5><strong>Apps</strong></h5>
                                        <div className="mt-2">
                                            <span className="btn" onClick={PDFShare}>
                                               
                                                <img style={{ borderRadius: '10px' }} src={boobybird} alt="brand" width={30} height={30}></img>
                                                &nbsp;Share PDF
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </MenuModel>
                    <div>
                        {authorizeFlag && 
                                <MainMenuDropdown />
                            }
                    </div>

                </div>
            </nav >
        </div>
)
}
/*
<MenuModel shown={show} close={() => {
    setShow(false);
}} >
    <div
        className="BaseNineDotMenu">
        <div>
            <div className='w-100  p-3'>
                <button onClick={onClickShowButton} className="btn btn-light">
                    <Ninedot style={{ width: '22px' }} />
                </button>
                <img className="float-end" src={booby} alt="brand" width={100}></img>
            </div>

            <div className="p-4 mt-4" style={{ display: 'flex' }}>
                <div style={{ display: 'block' }}>
                    <h5><strong>Apps</strong></h5>
                    <div className="mt-2">
                        <span className="btn" onClick={PDFShare}>
                                               
                            <img style={{ borderRadius: '10px' }} src={boobybird} alt="brand" width={30} height={30}></img>
                            &nbsp;Share PDF
                        </span>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</MenuModel>
*/
