import axios from 'axios';

export const SHAREDFROMME_SUCCESS = 'SHAREDFROMME_SUCCESS';
export const SHAREDFROMME_FAILURE = 'SHAREDFROMME_FAILURE';

export const SharedFromMe = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const documentID = {
            ID: id,
        }

        const response = await axios.post("/api/app/getsharedfromme",
            documentID
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        dispatch({
            type: SHAREDFROMME_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: SHAREDFROMME_FAILURE,
            payload: errors,
        });

    };
}
