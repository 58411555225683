import logo from "../../images/pdf.png";
import NavigationBar from './sideBarMenu';
import '../styles/style.css';
import ButtonGroup from './buttonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import MainMenuDropdown from './mainMenuDropdown';
import { Link } from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import DataTable, { createTheme } from "react-data-table-component";
import FilterComponent from './filterComponent';
import { getSharedWithMe } from '../../actions/getSharedWithMe';
import { useSelector, useDispatch } from 'react-redux';
import pdf from '../../images/pdf-ref2.png';
import moment from 'moment';
import HamburgerMenu from './toolbar';
import ListButtons from './ListBar';
import { ButtonActionsFlag } from '../../actions/ButtonActionsFlag';

createTheme("solarized", {
    text: {
        primary: "#268bd2",
        secondary: "#2aa198"
    },
    background: {
        default: "#002b36"
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF"
    },
    divider: {
        default: "#073642"
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)"
    }
});

export default function SharedWithMe() {
    const [SharedData, setSharedData] = useState([]);
    const [resetData, setResetData] = useState(null);
    const dispatch = useDispatch();
    const getSharedWithMeRedux = useSelector(state => state.getSharedWithMeRedux);
    const currentTime = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");
    const dmtosdr = useSelector(state => state.DeleteMoreThanOneSharedDocumentReducer);
    //const { data } = getSharedWithMeRedux;
    useEffect(() => {
        if (getSharedWithMeRedux && getSharedWithMeRedux.data && getSharedWithMeRedux.data.isSuccessful) {
            setSharedData(getSharedWithMeRedux.data.data);
        }
    }, [getSharedWithMeRedux]);

    useEffect(() => {
        dispatch(getSharedWithMe());
    }, []);

    useEffect(() => {
        if (dmtosdr && dmtosdr.data && dmtosdr.data.isSuccessful) {
            dispatch(getSharedWithMe());
        }
    }, [dmtosdr]);

    const Header1 = ({ n }) => <span><b>{n}</b></span>
    /*const onClickChanges = (event) => {
        event.preventDefault();
    }*/
    //<i className="fa-solid fa-file-pdf" style={{ color: 'rgb(40, 168, 226)', fontSize: '20px' }}></i>
    const columns = [
        {
            name: "View",
            selector: (row) => (
                <div className="btn-group-actions">
                    {row.expireDate.split('.')[0].replace('T', ' ').replace(/-/g, '/') > currentTime ?
                        <Link className="text-success btn btn-sm btn-success text-white" target='_blank' to={{
                            pathname: `/index/viewer/${row.document.id}`,
                        }} state={{ viewkey: row.viewKey, id: row.document.id, type: "LinkEntry" }} title="viewKey">
                            <i style={{ color: 'white' }} className="fa-regular fa-eye"></i>
                        </Link> : <Link style={{ pointerEvents: 'none' }} className="text-success btn btn-sm btn-success text-white;" title="viewKey" disabled>
                            <i style={{ color: 'white' }} className="fa-regular fa-eye"></i>
                        </Link>
                    }
                </div>
            ),
            width: "100px"
        }, {
            /*name: "Name",
            selector: (row) => row.document['name'],
            cell: row => <div><img src={pdf} alt="pdf" width={25} height={25}></img>&nbsp;{row.document['name']}</div>,
            sortable: true,
            minWidth: "200px",*/
            //sortFunction: (a, b) => a.localeCompare(b),
              name: <Header1 n={'Name/Description'} />,
            selector: (row) => row['model']['name'],
            cell: row => <div className="d-flex"><div style={{ alignItems: 'center', display: 'flex' }}><img style={{ marginRight: '10px' }} src={pdf} alt="pdf" width={25} height={25}></img></div><div>{row.document['name']}</div></div>,
            sortable: true,
            minWidth: "300px",
        },
        {
            name: <Header1 n={'Description'} />,
            selector: (row) => row['model']['description'],
            cell: row => <div className="d-flex"><div style={{ alignItems: 'center', display: 'flex' }}></div><div>{ row.document['description'] }</div></div>,
            sortable: true,
            minWidth: "250px",
        },
        {
            name: "# Pages",
            selector: (row) => row.document['totalPages'],
            sortable: true,
            width: "100px",
            center: true
        },
        {
            name: "Shared By",
            selector: (row) => row.createdBy,
            sortable: true,
            minWidth: "200px",
            maxWidth: "220px"
        }, {
            name: "Shared At",
            selector: (row) => row.createdAtRes.split('.')[0].replace('T', ' '),
            sortable: true,
            minWidth: "170px",
            maxWidth: "170px"
        },
        {
            name: "Expire At",
            selector: (row) => row.expireDate.split('.')[0].replace('T', ' '),
            sortable: true,
            minWidth: "170px",
            maxWidth: "170px"
        },
        {
            name: "Status",
            selector: (row) => (
                <div>
                    {
                        (row.expireDate.split('.')[0].replace('T', ' ').replace(/-/g, '/') < currentTime ? <span style={{ color: 'red' }}>Expired</span>
                            : <span style={{ color: 'green' }}>Active</span>)
                    }
                </div>

            ),
            sortable: true,
            minWidth: "100px",
            maxWidth: "170px"
        },
        

    ];

    const onClickChanges = (data) => {
        if (resetData && resetData !== null) {
            data.selectedRows = data.selectedRows.slice(0, (-1 * resetData));
            data.selectedCount = data.selectedCount - resetData;
            setResetData(null);
        }
        if (data) {
            dispatch(ButtonActionsFlag(data));
        }
    }

    const conditionalRowStyles = [
        {
            when: (row) => row,
            style: {
                "&:hover": {
                    backgroundColor: "green",
                    color: "white",
                    cursor: "pointer"
                }
            }
        }
        // You can also pass a callback to style for additional customization
    ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = SharedData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <hr />
                <div className="buttonGroups">
                    <ListButtons show={true} data={"sharedWithMe"} />
                    <div className="zrtykLm">
                        <FilterComponent
                            onFilter={e => setFilterText(e.target.value)}
                            onClear={handleClear}
                            filterText={filterText}
                        />
                        <HamburgerMenu data={filteredItems} type={"sharedWithMe"} />
                    </div>
                </div>
            </>
        );
    }, [filterText, resetPaginationToggle, SharedData]);

    const customPagination = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    }
    const tableCustomStyles = {
        table: {
            style: {
                borderCollapse: 'collapse',
            },
        },
        headRow: {
            style: {
                color: '#223336',
                //backgroundColor: '#e7eef0',
            },
        },
    }
    
    return (
        <div className="App">
            <DataTable
                customStyles={tableCustomStyles}
                columns={columns}
                data={filteredItems}
                defaultSortField="Name"
                striped
                pagination
                paginationComponentOptions={customPagination}
                paginationPerPage={30}
                defaultSortFieldId={6}
                defaultSortAsc={false}
                defaultSortOrder="desc"
                highlightOnHover
                subHeader
                subHeaderComponent={subHeaderComponent}
                selectableRows
                selectableRowsOnClick
                onSelectedRowsChange={onClickChanges}
            />
        </div>
    );
}
//  onRowClicked={handleRowClicked}