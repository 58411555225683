import axios from 'axios';

export const GETBALANCE_SUCCESS = 'GETBALANCE_SUCCESS';
export const GETBALANCE_FAILURE = 'GETBALANCE_FAILURE';



export const getBalance = () => async (dispatch) => { 
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post('/api/App/getbalance', null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETBALANCE_SUCCESS,
            payload: response.data,
        });

    }catch(errors){
        dispatch({
            type: GETBALANCE_FAILURE,
            payload: errors,
        });

    };
}
