import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from "react-redux";
import { getBalance } from "../../actions/getBalanceAction";
const Balance = () => {
    const [balance, setBalance] = useState([]);
    const dispatch = useDispatch();
    const getBalanceRedux = useSelector(state => state.getBalanceRedux)

    useEffect(() => {
        if (getBalanceRedux && getBalanceRedux.data && getBalanceRedux.data.isSuccessful) {
            setBalance(getBalanceRedux.data.data.balance.toFixed(2));
        }
    }, [getBalanceRedux]);

    useEffect(() => {
        dispatch(getBalance());
    }, []);

    //console.log("balance",getBalanceRedux.data.data.balance);
    return (
        <div className="d-flex mainMenuLink">
            {getBalanceRedux && getBalanceRedux.data &&
                <>
              

                <input className="form-control" id="credits" type="text" value={balance} readOnly />
                </>
            }
        </div>

    );
}

export default Balance;
/* <i className="fa fa-credit-card"></i>
*/
//  <span style={{ color: 'rgb(40, 168, 226)' }}><i className="fa-solid fa-money-check-dollar"></i> &nbsp;Credits: {getBalanceRedux.data.data.balance.toFixed(2)}</span>