import { Link } from 'react-router-dom'
export default function NotFound_404() {
    return (
        <div className="booby2k_wp">
            <div className="text-center vh-100 d-flex justify-content-center align-items-center">
                <div>
                    <h1 className="page_404_color display-1">404</h1><br></br>
                    <h2 className="page_404_color">Error</h2><br></br>
                    <h3 className="page_404_color">I think you just went to non-existing page</h3>
                    <Link className="btn page_404_go_home page_404_color" to='/index'>Go to Home Page</Link>
                </div>
            </div>
        </div>
    )
}