import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const FORGETPASSWORDFORM_SUCCESS = 'FORGETPASSWORDFORM_SUCCESS';
export const FORGETPASSWORDFORM_FAILURE = 'FORGETPASSWORDFORM_FAILURE';


export const ForgetPasswordFormAction = (userID, password, code) => async (dispatch) => {
    try {
        const ForgetUser = {
            userID: userID,
            Password: password,
            code: code,
        };
        await axios.post("/api/Main/ForgetPasswordAsk", ForgetUser)
        .then(function (response) {
            dispatch({
                type: FORGETPASSWORDFORM_SUCCESS,
                payload: response.data,
            })
            toast.success('Password sucessfully changed!', {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    } catch (errors) {
        toast.error(errors.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: FORGETPASSWORDFORM_FAILURE,
            payload: errors,
        });

    };
}
