import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DELETEMORETHANONE_SHAREDDOCUMENT_SUCCESS = 'DELETEMORETHANONE_SHAREDDOCUMENT_SUCCESS';
export const DELETEMORETHANONE_SHAREDDOCUMENT_FAILURE = 'DELETEMORETHANONE_SHAREDDOCUMENT_FAILURE';
export const DELETEMORETHANONE_SHAREDDOCUMENT_RESET = 'DELETEMORETHANONE_SHAREDDOCUMENT_RESET';

export const DeleteMoreThanOneSharedAction = (ids) => async (dispatch) => {

    if (!ids) {
        dispatch({
            type: DELETEMORETHANONE_SHAREDDOCUMENT_RESET,
        });
    } else {
        try {
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
            const token = tokenStorage.data.accessToken;
            const ID = {
                IDs: ids,
            }

            await axios.post('/api/Main/DeleteMoreThanOneShared', ID, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('Shared document succesfully removed', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETEMORETHANONE_SHAREDDOCUMENT_SUCCESS,
                    payload: response.data,
                    count: ids.split(',').length - 1,
                });
            });

        } catch (error) {
            toast.error(error.response.data.error.errors[0], {
                position: toast.POSITION.TOP_RIGHT
            })
            dispatch({
                type: DELETEMORETHANONE_SHAREDDOCUMENT_FAILURE,
                payload: error,
            });
        }
    }

}

