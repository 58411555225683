import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DELETEUSERIMAGE_SUCCESS = 'DELETEUSERIMAGE_SUCCESS';
export const DELETEUSERIMAGE_FAILURE = 'DELETEUSERIMAGE_FAILURE';
export const DELETEUSERIMAGE_RESET = 'DELETEUSERIMAGE_RESET';

export const DeleteUserImage = (flag) => async (dispatch) => {
    if (!flag) {
        dispatch({
            type: DELETEUSERIMAGE_RESET,
            payload: null,
        });
    } else {
        try {
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
            const token = tokenStorage.data.accessToken;
            await axios.post("/api/Main/DeleteUserImage", null, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('User Image removed successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETEUSERIMAGE_SUCCESS,
                    payload: response.data,
                });
            });
        } catch (error) {
            toast.error(error.response.data.error.errors[0], {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: DELETEUSERIMAGE_FAILURE,
                payload: error,
            });
        }
    }

}




