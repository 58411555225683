import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const SENDFEEDBACKEMAIL_SUCCESS = 'SENDFEEDBACKEMAIL_SUCCESS';
export const SENDFEEDBACKEMAIL_FAILURE = 'SENDFEEDBACKEMAIL_FAILURE';

export const sendFeedBackEmail = (email, feedback) => async (dispatch) => {
    try {
        const input = {
            email: email,
            feedback :feedback,
        }

        await axios.post('/api/Main/SendFeedBackEmail', input)
        .then(function (response) {
            dispatch({
                type: SENDFEEDBACKEMAIL_SUCCESS,
                payload: response.data,
            });
            toast.success('Feedback successfully sent', {
                position: toast.POSITION.TOP_RIGHT
            });
        })

    } catch (errors) {
        //not authenticated give error
        toast.error(errors.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: SENDFEEDBACKEMAIL_FAILURE,
            payload: errors,
        });

    };
}

