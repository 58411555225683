import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/login.css';
import { sendVerificationEmail } from '../../actions/sendVerificationEmail';
import { useDispatch } from 'react-redux'
import booby from '../../images/Logo_removebg.png';
export default function RegisterNotConfirmation(){
    const dispatch = useDispatch();
    const { state } = useLocation();
    
    const  [email,setEmail] = useState(state && state.email !== null ? state.email : "");
    const reSendMail = (event) => {
        event.preventDefault();
        dispatch(sendVerificationEmail(email));
    }

    return (
        <div className="row AuthTL">
            <div className="col-md-8">
                <span className="confirm" style={{ textAlign: 'center', width: '50%', display:'block'}}>
                    Please confirm your email address, if you not received confirmation email yet please <Link onClick={reSendMail} to="/registerNotConfirmedPosted">click here</Link>.
                </span>
            </div>
        </div>
    );
    
}
   