import axios from 'axios';

export const GETDELETEDDOCUMENT_SUCCESS = 'GETDELETEDDOCUMENT_SUCCESS';
export const GETDELETEDDOCUMENT_FAILURE = 'GETDELETEDDOCUMENT_FAILURE';

export const GetDeletedByMe = () => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post('/api/App/getdeletedbyme', null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETDELETEDDOCUMENT_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: GETDELETEDDOCUMENT_FAILURE,
            payload: errors,
        });

    };
}
