import React, { Component } from 'react';
import { Outlet } from 'react-router';
import { Footer } from './footer';
export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <>
            <Outlet />
            <Footer />
      </>
    );
  }
}

/*
    <div className="PDFDocumentBody container-login">
        {this.props.children}
    </div>
*/
