
const initialState = {
    isAuthenticated: false,
    busy: false,
    token: null,
};

export const loginRedux = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_LOADING':
            return {
                ...state,
                busy:true,
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                busy: false,
                token: action.payload,
            };
        case 'LOGIN_FAILURE':
            return {
                ...state,
                isAuthenticated: false,
                busy: false,
                token: null,
                error: action.payload
            };
        case 'LOGOUT':
            return {
                ...state,
                busy: false,
                isAuthenticated: false,
                token: null,
            }
        default:
            return state;
    }
}

export default loginRedux;