import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DELETEMORETHANONEDOCUMENT_SUCCESS = 'DELETEMORETHANONEDOCUMENT_SUCCESS';
export const DELETEMORETHANONEDOCUMENT_FAILURE = 'DELETEMORETHANONEDOCUMENT_FAILURE';
export const DELETEMORETHANONEDOCUMENT_RESET = 'DELETEMORETHANONEDOCUMENT_RESET';

export const DeleteMoreThanOneDocumentAction = (ids) => async (dispatch) => {
    if (!ids) {
        dispatch({
            type: DELETEMORETHANONEDOCUMENT_RESET,
        });
    } else {
        try {
            const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
            const token = tokenStorage.data.accessToken;
            const ID = {
                IDs: ids,
            }

            await axios.post('/api/Main/DeleteMoreThanOne', ID, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                toast.success('Documents recycle successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETEMORETHANONEDOCUMENT_SUCCESS,
                    payload: response.data,
                    count: ids.split(',').length - 1,
                });
            });

        } catch (error) {
            toast.error(error.response.data.error.errors[0], {
                position: toast.POSITION.TOP_RIGHT
            })
            dispatch({
                type: DELETEMORETHANONEDOCUMENT_FAILURE,
                payload: error,
            });
        }
    }

}

