
import React, { Component, useState, useEffect } from 'react';
import '../styles/login.css';
import { useNavigate, Link } from 'react-router-dom';
import { RESENDEMAIL_RESET, sendVerificationEmail } from '../../actions/sendVerificationEmail';
import { useDispatch, useSelector } from 'react-redux'
import booby from '../../images/Logo_removebg.png';
import RegisterNotConfirmation from './registerNotConfirmation';

function ReSendMailConfirmation() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [output, setOutput] = useState("");
    const [error, setError] = useState("");
    const sendVerificationEmailRedux = useSelector(state => state.sendVerificationEmailRedux)
    const dispatch = useDispatch();
    
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(sendVerificationEmail(email));
        setEmail("");
    }

    const changeEmail = (event) => {
        setEmail(event.target.value);
    }
    
    useEffect(() => {
        if (sendVerificationEmailRedux && sendVerificationEmailRedux.data && sendVerificationEmailRedux.data.isSuccessful === true) {
            setOutput("confirmation mail sended");
            navigate("/RegisterNotConfirmation");
            dispatch({ type: RESENDEMAIL_RESET }); 

        } else if (sendVerificationEmailRedux.error) {
            setError(sendVerificationEmailRedux.error.errors[0]);
         
        }
    }, [sendVerificationEmailRedux]);



    return (
            <>
            <div className="row  AuthTL">
                
                <div className="col-md-4 d-flex justify-content-center">
                <section>
                        
                        <form method="post" className="confirm" id="account" onSubmit={handleSubmit}>
                            <img src={booby} alt="Bymmy App" style={{ 'float': 'right', 'marginRight': '-25px' }}></img>
                            <h2 style={{ 'fontWeight': 'bold' }}>Confirmation</h2>
                            <hr />
                            <div asp-validation-summary="All" className="text-danger"></div>
                            <div className="mb-3 ">
                                <label className="form-label">Email</label>
                                <input className="form-control" value={email} onChange={changeEmail}  aria-required="true" />
                            </div>
                            <p style={{'textAlign':'center'}}>
                                Please enter your email address to receive confirmation
                            </p>
                            {output && output !== '' && <span className="success" style={{ color: 'green' }}>{output}</span>}
                            {error && error !== "" && <span className='errMsg'> - {error}</span>}
                            <div className="pb-4">
                                <button type="submit" style={{ 'fontWeight': 'bold' }} className="w-100 btn btn-lg  btn-spf-grn">Resend</button>
                            </div>
                            <div>
                                <p style={{ 'fontSize': '13px' }}>
                                    Forget your password?  <Link to="/ForgetPassword">Reset Password</Link>
                                    <br />
                                    Don't have an account? <Link to="/SignIn">Sign Up</Link>
                                </p>
                                
                            </div>
                        </form>
                    
                </section>
                </div>
            </div>
            </>
        );
}

export default ReSendMailConfirmation;

/*                             {error < span className="text-danger">{error}</span>} */







