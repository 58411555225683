import axios from 'axios';

export const GETSHAREDFROMMEALL_SUCCESS = 'GETSHAREDFROMMEALL_SUCCESS';
export const GETSHAREDFROMMEALL_FAILURE = 'GETSHAREDFROMMEALL_FAILURE';

export const GetSharedFromMeAll = () => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const response = await axios.post("/api/App/getsharedfrommeall",
            null
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        dispatch({
            type: GETSHAREDFROMMEALL_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: GETSHAREDFROMMEALL_FAILURE,
            payload: errors,
        });

    };
}
