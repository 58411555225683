import axios from 'axios';
//silinicek action ve reducer
export const VIEWERFILESTORAGE_SUCCESS = 'VIEWERFILESTORAGE_SUCCESS';
export const VIEWERFILESTORAGE_FAILURE = 'VIEWERFILESTORAGE_FAILURE';
export const VIEWERFILESTORAGE_FETCHING = 'VIEWERFILESTORAGE_FETCHING';

export const ViewerFileStorage = (id, currentPage) => async (dispatch) => {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
    const page = { ID: id, PageID: currentPage }

    dispatch({
        type: VIEWERFILESTORAGE_FETCHING,
        payload:true,
    });


        await axios.post('/api/Main/Page', page,{
            headers: {
                'Authorization': 'Bearer ' + token,
                'responseType': 'arrayBuffer',
            }
        }).then(response => {
            /*
            var base64_tmp = new TextEncoder().encode(response.data);
            var base64 = btoa(base64_tmp);
            var du = `data:image/jpeg;base64,${base64}`;
            */

            dispatch({
                type: VIEWERFILESTORAGE_SUCCESS,
                payload: response.data,
            });

        }).catch(errors => {
            dispatch({
                type: VIEWERFILESTORAGE_FAILURE,
                payload: errors.response.data,
            });
        })
  
}

