import React, { useState, useEffect } from 'react';
import Modal from "./modal";
import { useSelector, useDispatch } from 'react-redux';
import { sendFeedBackEmail } from '../../actions/sendFeedBackEmail';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GiveFeedback = (props) => {
    const [feedback, setFeedback] = useState('');
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const sendFeedBackEmailReducer = useSelector(state => state.sendFeedBackEmailReducer);
    
    const [documentTitle, setDocumentTitle] = useState("Feedback");
    const [isFeedBack, setIsFeedBack] = useState(false);
    const onSendEmail = (event) => {
        event.preventDefault();
        dispatch(sendFeedBackEmail(JSON.parse(localStorage.user).email, feedback));
        setShowModal(false);
    }

    const onChangeFeedback = (event) => {
        event.preventDefault();
        setFeedback(event.target.value);
    }

    const onClickIsFeedback = (event) => {
        event.preventDefault();
        setIsFeedBack(event.target.value);
    }

    useEffect(() => {
        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true && ShowModalFlagReducer.show === "feedback") {
                setShowModal(true);
            } else if (ShowModalFlagReducer.data === false && ShowModalFlagReducer.show === "feedback") {
                setShowModal(false);
            }
        }
    }, [ShowModalFlagReducer])

    const cancelButton = (event) => {
        event.preventDefault();
        setShowModal(false);
    }

    const style = {
        zIndex: '999',
        marginTop: '10px',
        position: 'absolute',
        backgroundColor: 'white',
        width: '500px',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign: 'left',
        color: 'black',
    }

    const buttonStyle = {
        display: 'flex',
        flexDirection: 'row-reverse'
    }
    return (
        <div>
            <Modal onHide={() => setShowModal(false)} styleProps={style} onClose={() => setShowModal(false)} show={showModal} titleProps={documentTitle}>
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="comment">
                                Your opinion is very important to us. We appreciate your feedback and will use it to evaluate changes and make improvements in our site.
                            </label>
                            <textarea id="comment" onChange={onChangeFeedback}  value={feedback} className="form-control" required="" name="comment">
                            </textarea>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={onClickIsFeedback} type="checkbox" name="feedbackemail" value={isFeedBack}></input>
                            <label className="form-check-label" htmlFor="feedback-email">Bymmy can email you about your feedback</label>
                        </div>
                        <div className="form-group mt-3">
                            <p>Thank you for taking the time to give us feedback.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-m-4" style={buttonStyle}>
                        <button onClick={onSendEmail} className="btn btn-success m-3 lift">Submit</button>
                        <button onClick={cancelButton} className="btn btn-danger m-3 lift text-white">Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default GiveFeedback;