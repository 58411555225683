import axios from 'axios';

export const GETSTATS_SUCCESS = 'GETSTATS_SUCCESS';
export const GETSTATS_FAILURE = 'GETSTATS_FAILURE';

export const StatsAction = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const documentId = {
            ID: id,
        }
        const response = await axios.post("/api/Main/Stats", documentId , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        dispatch({
            type: GETSTATS_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        dispatch({
            type: GETSTATS_FAILURE,
            payload: errors,
        });

    };
}