import React, { Component } from 'react';
import logo from "../../images/pdf.png";
import NavigationBar from './sideBarMenu';
import '../styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, Link } from 'react-router-dom';
export default function Account(props) {
    const navigate = useNavigate();
    const uploadClickEvent = () => {
        navigate('/Upload');
    } 
    return (  
<div style={{display: 'flex'}}>
    <NavigationBar />
    <div className="section" style={{width: '100%' }}>
        <div className="pb-8 pb-md-11 mt-md-n6">
            <div className="container-md">
                <div className="row">
                    <div className="col-4 p-5">
                        <div className="card mb-2">
                            <div className="card-body">
                                <h6 className="fw-bold text-uppercase mb-3">Account</h6>
                                <ul className="card-list list text-gray-700 mb-4 ">
                                    <li className="list-item  active"><Link className="list-link text-reset" to="/account/generalProfile">General</Link></li>

                                    <li className="list-item "><a className="list-link text-reset mx-1" href="/account/dictionary">Dictionary</a></li>
                                            <li className="list-item"><a className="list-link text-reset mx-1" href="/account/editor">Editor Settings</a></li>
                                            <li className="list-item"><a className="list-link text-reset mx-1" href="/account/uploads">Uploads</a></li>
                                            <li className="list-item"><a className="list-link text-reset mx-1" href="/account/refer">Refer</a></li>
                                </ul>
                                <h6 className="fw-bold text-uppercase mb-3">Billing</h6>
                                <ul className="card-list list text-gray-700 mb-4">
                                            <li className="list-item"><a className="list-link text-reset mx-1" href="/subscription">Subscription</a></li>
                                            <li className="list-item"><a className="list-link text-reset mx-1" href="/subscription/invoices">Invoices</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                <div className="col-8 p-5">
                    <div className="card card-bleed shadow-light-lg mb-6"><div className="card-header">
                        <h4 className="mb-0">Invoices</h4>
                    </div>
                    <div className="card-body">
                        <div className="list-group list-group-flush">
                            <h5>No invoices found.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    </div>
    </div>

</div>


)}