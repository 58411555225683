import React, { useState, useEffect } from 'react';
import Modal from "./modal";
import { useDispatch, useSelector } from 'react-redux';
import { EditDocumentPropAction } from '../../actions/EditDocumentProp';
import { ToastContainer, toast } from 'react-toastify';
import { ShowModalFlag, RESETMODALFLAG, SHOWMODALFLAG } from '../../actions/showModalFlag';
import { EditFileAction, EDIT_FILE_RESET } from '../../actions/EditFileAction';
import 'react-toastify/dist/ReactToastify.css';

const Rename = (props) => {
    const dispatch = useDispatch();
    const [namePDF, setNamePDF] = useState('');
    const [id, setId] = useState('');
    const [data, setData] = useState([]);
    const [description, setDescription] = useState('');
    const ButtonActionsFlagReducer = useSelector(state => state.ButtonActionsFlagReducer);
    const [documentTitle, setDocumentTitle] = useState("Edit PDF");
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const EditFileReducer = useSelector(state => state.EditFileReducer);

    /*useEffect(() => {
        if (ButtonActionsFlagReducer && ButtonActionsFlagReducer.data && ButtonActionsFlagReducer.data.length > 0) {
            setNamePDF(ButtonActionsFlagReducer.data[0].model.name);
            setId(ButtonActionsFlagReducer.data[0].id);
            setData(ButtonActionsFlagReducer.data[0].model);
            setDescription(ButtonActionsFlagReducer.data[0].model.description);
        }
    }, [ButtonActionsFlagReducer])*/

    useEffect(() => {
        if (EditFileReducer && EditFileReducer.data) {
            setNamePDF(EditFileReducer.data.name);
            setId(EditFileReducer.data.id);
            setData(EditFileReducer.data);
            setDescription(EditFileReducer.data.description);
        }
    }, [EditFileReducer])

    useEffect(() => {
        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true && ShowModalFlagReducer.show === "edit") {
                setShowModal(true);
                dispatch({ type: SHOWMODALFLAG });
            } else if (ShowModalFlagReducer.data === false && ShowModalFlagReducer.show === "edit") {
                setShowModal(false);
                dispatch({ type: RESETMODALFLAG });
                dispatch({ type: EDIT_FILE_RESET });
                
            }
        }
    }, [ShowModalFlagReducer])

    const onChangeNameOfPDF = (event) => {
        event.preventDefault();
        setNamePDF(event.target.value);
    }

    const ChangeNamePDF = (event) => {
        event.preventDefault();
        setShowModal(false);
        dispatch({ type: RESETMODALFLAG });
        dispatch({ type: EDIT_FILE_RESET });
        dispatch(EditDocumentPropAction(id, namePDF, description));
    } 
    
    const onChangeDescriptionOfPDF = (event) => {
        event.preventDefault();
        setDescription(event.target.value);
    }

    const onCloseModalEvent = (event) => {
        setShowModal(false);
        dispatch({ type: RESETMODALFLAG });
        dispatch({ type: EDIT_FILE_RESET });
    }
    const style = {
        zIndex: '999',
        marginTop: '10px',
        position: 'absolute',
        backgroundColor: 'white',
        width: '500px',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign: 'left',
    }
    return (
        <div>
            <Modal onHide={() => onCloseModalEvent()} styleProps={style} onClose={() => onCloseModalEvent()} show={showModal} titleProps={documentTitle}>
                <div>
                    <div className="row mt-2">
                        <div className="col-12">                        
                            <div className="form-group mb-3 ">
                                <label className="control-label col-md-4" htmlFor="Name">Name</label>
                                <div className="col-md-12">
                                    <input className="form-control signle-line" onChange={onChangeNameOfPDF}  required id="Name" name="Name" type="text" value={namePDF}></input>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-3 ">
                                <label className="control-label col-md-4" htmlFor="Description">Description</label>
                                <div className="col-md-12">
                                    <textarea className="form-control signle-line" onChange={onChangeDescriptionOfPDF} required id="Description" name="Description" type="text" value={description}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{textAlign:'right'}}>
                    <button className="btn btn-secondary m-1 lift" onClick={() => onCloseModalEvent()}>Close</button>
                    <button onClick={ChangeNamePDF} className="btn btn-primary m-1 sharenow"><i className="fa regular fa-pen-to-square" style={{ color: 'white' }} ></i>&nbsp;Change</button>
                </div>
            </Modal>
        </div>
    );
}

export default Rename;