import axios from 'axios';
export const RESENDEMAIL_RESET = 'RESENDEMAIL_RESET';
export const RESENDEMAIL_SUCCESS = 'RESENDEMAIL_SUCCESS';
export const RESENDEMAIL_FAILURE = 'RESENDEMAIL_FAILURE';

export const sendVerificationEmail = (Email) => async (dispatch) => {
    if (!Email || Email === "") {
        dispatch({
            type: RESENDEMAIL_RESET,
            payload: null,
        });
    } else {
        try {
            const inputEmail = {
                Email: Email,
            }

            const response = await axios.post('/api/SignIn/SendVerificationEmail', inputEmail);
            //authenticated redirect home page
            dispatch({
                type: RESENDEMAIL_SUCCESS,
                payload: response.data,
            });

        } catch (errors) {
            //not authenticated give error
            dispatch({
                type: RESENDEMAIL_FAILURE,
                payload: errors.response.data.error,
            });

        };
    }

   
}

