import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const FETCHEDITSHAREPOST_SUCCESS = 'FETCHEDITSHAREPOST_SUCCESS';
export const FETCHEDITSHAREPOST_FAILURE = 'FETCHEDITSHAREPOST_FAILURE';
export const FETCHEDITSHAREPOST_BUSY = 'FETCHEDITSHAREPOST_BUSY';
export const fetchEditSharePost = (id, email_address, multiExpireDate, allow_download_multi) => async (dispatch) => {
    try {
        dispatch({
            type: FETCHEDITSHAREPOST_BUSY,
            busy:true,
            payload: null,
        });

        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        const MultiSharePost = {
            ID: id,
            email: email_address,
            expireDate: multiExpireDate,
            allow_download: allow_download_multi,
        }
        await axios.post('/api/Main/FetchEditSharePost', MultiSharePost, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(function (response) {
            toast.success('PDF document has been successfully shared!', {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch({
                type: FETCHEDITSHAREPOST_SUCCESS,
                busy: false,
                payload: response.data,
            });
        });
    } catch (error) {
        toast.error(error.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: FETCHEDITSHAREPOST_FAILURE,
            busy: false,
            payload: error.response.data.error,
        });
    }
}

